import styled from "styled-components";
import { FaMagento } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Container } from "../../globalStyles";

const activeClassName = "nav-item-active";

export const Nav = styled.nav`
  background: #101522;

  background: linear-gradient(90deg, #005c97 0%, #363795 100%);
  background: #363795;

  /* background: linear-gradient(90deg, #0575e6 0%, #021b79 100%); */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  zoom: 100%;
  font-weight: 100;
  /* max-width: 1000px; */
  margin-right: auto;
  margin-left: auto;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 30px;

  ${Container}
`;

export const NavLogo = styled(NavLink)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  margin-top: 0.5rem;
`;

export const NavIcon = styled(FaMagento)`
  margin-right: 0.5rem;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;

    top: -10px;
    right: 0;

    transform: translate(-100%, 60%);
    font-size: 1.8rem;

    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-top: 0.8rem;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    align-items: flex-start;
    list-style: none;
    text-align: left;
    top: 60px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: linear-gradient(90deg, #005c97 0%, #363795 100%);
    margin-top: 0rem;
  }
`;

// export const NavItem = styled.li`
//   height: 55px;
//   border-bottom: 2px solid transparent;
//   border-left: 1px solid #dd2476;
//   border-right: 1px solid #dd2476;
//   &:hover {
//     background-color: #dd2476;
//   }

//   @media screen and (max-width: 960px) {
//     width: 100%;

//     &:hover {
//       border: none;
//     }
//   }
// `;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

// Test
export const NavLinks = styled(NavLink).attrs({ activeClassName })`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.1rem 1rem;
  height: 100%;
  font-size: 15px;
  height: 67px;
  border-left: 0.001px solid #a4a5a5;
  border-right: 0.001px solid #a4a5a5;
  font-weight: 600;
  /* border-bottom: 1px solid #dd2476; */
  &:hover {
    background-color: #f50057;
    color: white;
  }
  &.${activeClassName} {
    background-color: #f50057;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    border: none;
    &:hover {
      border: none;
    }
  }
`;

export const NavBtnLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 18px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
