import React from "react";
import { Button } from "../../globalStyles";
import { AiFillThunderbolt } from "react-icons/ai";
import { GiCrystalBars } from "react-icons/gi";
import { GiCutDiamond, GiRock } from "react-icons/gi";
import { GiFloatingCrystal } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
} from "./Pricing.elements";

function Pricing() {
  return (
    <IconContext.Provider
      value={{
        color: "linear-gradient(90deg, #005c97 0%,#363795 100% );",
        size: 64,
      }}
    >
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Pricing</PricingHeading>
          <PricingContainer>
            <PricingCard to="/signin">
              <PricingCardInfo>
                <PricingCardPlan>Starter Pack</PricingCardPlan>
                <PricingCardCost>$00.00</PricingCardCost>
                <PricingCardLength>per post</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Access Free</PricingCardFeature>
                  <PricingCardFeature>
                    Dashboard under Budget
                  </PricingCardFeature>
                  <PricingCardFeature>
                    Available for free use
                  </PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
              {/* <Button primary style={buttonStyle}>
                Choose Plan
              </Button> */}
            </PricingCard>
            <PricingCard to="/signin">
              <PricingCardInfo>
                <PricingCardPlan>Gold Plan</PricingCardPlan>
                <PricingCardCost>$299.99</PricingCardCost>
                <PricingCardLength>per month</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>1000 New Users</PricingCardFeature>
                  <PricingCardFeature>$50,000 Budget</PricingCardFeature>
                  <PricingCardFeature>Lead Gen Analytics</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to="/signin">
              <PricingCardInfo>
                <PricingCardPlan>Diamond Kings</PricingCardPlan>
                <PricingCardCost>$999.99</PricingCardCost>
                <PricingCardLength>per month</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Unlimited Users</PricingCardFeature>
                  <PricingCardFeature>Unlimited Budget</PricingCardFeature>
                  <PricingCardFeature>24/7 Support</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
              {/* <Button primary style={buttonStyle}>
                Choose Plan
              </Button> */}
            </PricingCard>
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
}

const buttonStyle = {
  width: "100%",
  marginTop: -3,
  borderRadius: 0,
};
export default Pricing;
