import React, { useState, useEffect } from "react";
import Card from "../../components/FuneralDirectors";
import { CardMain } from "../../components/FuneralDirectors/card__elements";
import Footer from "../../components/Footer/Footer";
import { Input } from "antd";
import Data from "./data.js";
const Index = () => {
  const [alldata, setAlldata] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    setAlldata(Data);
  }, [alldata]);

  const list = alldata
    .filter(
      (data) =>
        input === "" ||
        data.title.includes(input) ||
        data.country.includes(input) ||
        data.details.includes(input)
      // data.country.includes(input)
    )
    .map((d, index) => (
      <Card
        key={index}
        country={d.country}
        title={d.title}
        description={d.details}
      />
    ));

  return (
    <>
      <div
        style={{
          margin: "0 auto",
          marginTop: "4%",
          maxWidth: 1000,
          textAlign: "center",
        }}
      >
        <Input
          style={{ width: "100%" }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Search Cremotrium by  Country, Address , Phone Number here"
        />
        <span style={{ margin: 10, color: "red" }}>
          * Use propper cases to search
        </span>
      </div>

      <div
        className="majorcard"
        style={{
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 1000,
          margin: "0 auto",
          height: "120vh",
          overflowY: "scroll",
        }}
      >
        <CardMain>{list}</CardMain>
      </div>
      <Footer style={{ position: "fixed", bottom: 0 }} />
    </>
  );
};

export default Index;
