import React, { useState } from "react";
import "react-widgets/styles.css";
import { Calendar } from "react-widgets";
import { CalanderContainer } from "./Calander.elements";
function Index() {
  const [date, setDate] = useState(new Date());

  return (
    <>
      <CalanderContainer>
        <Calendar
          value={date}
          className="calander"
          onChange={(value) => setDate(value)}
        />
      </CalanderContainer>
    </>
  );
}

export default Index;
