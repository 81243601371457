const data = [
  {
    country: "Australia ",
    title: "T.J. ANDREWS – WARREN TYERS",
    details: `
Auburn Road,
Auburn
Australia,
`,
  },

  {
    country: "Canada ",
    title: "  Benisasia Funeral Home INC",
    details: `
1357 Queen
st. west Toronto
`,
  },
  {
    country: "Canada ",
    title: "Funeral Home & Cremation Centre",
    details: `
3280 Shepperd Ave
E.Toronto
Canada,

`,
  },
  {
    country: "Canada ",
    title: "Ogden Funeral Homes",
    details: `

4164 Shepperd Ave
E.Toronto
Canada,


`,
  },
  {
    country: "Canada ",
    title: "The Moddcham Funeral Home",
    details: `

4242 B Kingston Road,
Scarborogh, ontario
Canada,


`,
  },
  {
    country: "Canada ",
    title: "Canadian Tamil Furerals",
    details: `


2505 Eglington Avenue
E Toronto, Ontario
Canada,





`,
  },
  {
    country: "Canada ",
    title: "Heaven Cares",
    details: `


8911 Woodbine Ave.,
Markham,ON.
Canada, L3R 5G1


`,
  },
  {
    country: "Canada ",
    title: "Canadian Tamil Funerals",
    details: `
2505 Eglinton Ave., E.,
Toronto, Ontario M1K 2R1
Canada
T: 416-264-7033 
C: 416-265-2652 




`,
  },
  {
    country: "Canada ",
    title: "Highland Funeral Home",
    details: `



3280 Sheppard Ave., East
Scarborough, Ontario 
Canada
T: 416-773-0933 




`,
  },
  {
    country: "Canada ",
    title: "The Moddcham Funeral Home",
    details: `


Address:
4242 B Kingston Rd.
Scarborough, Ontario 
Canada
T: 416-282-6999 
C: 416-282-6999


`,
  },
];
export default data;
