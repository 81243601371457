import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import SideBar from "./SideContainer";
import {
  ScreenContainer,
  MainContainer,
  SideContainer,
} from "../../components/MainContainer/MainContainer.elelents";
import Card from "../../components/AdminCard/AdminCard";
import Data from "../../mocks/passingcarddata";
import { Button, Container } from "../../globalStyles";

function Index() {
  //   const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  const history = useHistory();

  const post = () => {
    // history.push("/dashboard");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Loader /> */}
      <Container isZoom>
        <ScreenContainer style={{ margin: 0, background: "#fff" }}>
          <SideBar />
          <MainContainer
            style={{
              display: "flex",
              flex: 1,
              height: "80vh",
              background: "#fff",
              marginTop: -10,
              paddingBottom: "30%",
            }}
          >
            {/* PROFILE */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              {/* <img
                src={state ? state.pic : "loading"}
                style={{ height: 300, width: 340, borderRadius: 50000 }}
              /> */}
              <div style={{ margin: 40 }}>
                <p style={stylesx}>
                  {" "}
                  <span style={{ color: "tomato" }}> _user id: </span>
                  <br />
                  {state ? state._id : ""}
                </p>
                <p style={stylesx}>
                  {" "}
                  <span style={{ color: "tomato" }}> _user name: </span>
                  <br />
                  {state ? state.name : ""}
                </p>
                <p style={stylesx}>
                  {" "}
                  <span style={{ color: "tomato" }}> _user email: </span>
                  <br />
                  {state ? state.email : ""}
                </p>{" "}
                <p style={stylesx}>
                  {" "}
                  <span style={{ color: "tomato" }}> _user address: </span>
                  <br />
                  {state ? state.address : ""}
                </p>
                <p style={stylesx}>
                  {" "}
                  <span style={{ color: "tomato" }}> _user phone: </span>
                  <br />
                  {state ? state.phone : ""}
                </p>
              </div>
            </div>
          </MainContainer>
        </ScreenContainer>
      </Container>
    </>
  );
}

const styles = {
  marginBottom: -4,
  fontSize: 20,
  marginRight: 15,
  marginLeft: 15,
};
const stylesx = {
  fontSize: 25,
  marginRight: 15,
  marginLeft: 15,
  marginBottom: 10,
};

export default Index;
