import React from "react";
import {
  homeObjOne,
  // homeObjTwo,
  homeObjThree,
  // homeObjFour,
} from "../Services/Data";
import { InfoSection, MainContainer } from "../../components";
import Ticker from "../../components/Ticker/Ticker";
import { Container, Header } from "../../globalStyles";
import FG from "../../images/fh.jpg";
import SCD from "./serviceHome";
import Footer from "../../components/Footer/Footer";
import Countries from "../../components/Ticker/Countries";
// import Detailview from "../../components/DetailView/DetailView";
// import { Container } from "../../globalStyles";
function Home() {
  return (
    <div style={{ background: "#fff", height: "200vh" }}>
      {/* <Container> */}
      {/* <InfoSection {...homeObjOne} /> */}
      {/* <img src={FG} style={{ height: 200, width: "100% " }} /> */}
      <Header />
      <Ticker />
      <MainContainer />

      {/* <InfoSection {...homeObjOne} /> */}
      {/* <SCD /> */}
      {/* <InfoSection {...homeObjThree} /> */}
      <Countries />
      <Footer />
      {/* </Container> */}
      {/* <Detailview />
      <Container>
        <Ticker />
      </Container> */}
    </div>
  );
}

export default Home;
