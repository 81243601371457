// LIST OF PRODUCTS

import Service1 from "../../../images/34.svg";
import Service2 from "../../../images/fg.svg";
import Service3 from "../../../images/67.svg";

const data = [
  {
    _id: 12058,
    name: "Plan Ahead-Pre plan",
    service_image: Service1,
    description:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled.",
    button_text: "Explore Menu",
    isprimary: true,
    isBigPadding: false,
  },
  {
    _id: 124058,
    name: "Experiance the difference",
    isprimary: true,
    service_image: Service2,
    description:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled.",
    button_text: "Find Stores",
    isprimary: true,
  },
  {
    _id: 158,
    name: "Honoring the life",
    service_image: Service3,
    description:
      "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled.",
    button_text: "Bulk Orders",
    isprimary: true,
    isBigPadding: false,
  },
];

export default data;
