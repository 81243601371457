import styled, { createGlobalStyle } from "styled-components";
// import BG from "./images/fgg.jpg";
// import BG from "http://www.concernsrilanka.com/images/concernsl_logo.jpg";
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background:"#f1f1f1";
  /* font-family: 'Popins', sans-serif; */
  font-family: 'Lato', sans-serif;
      /* font-family: Muli;  */
   /* zoom:98.5%; */





 } 
`;

export const Container = styled.div`
  zoom: ${({ isZoom }) => (isZoom ? "100%" : "79%")};
  z-index: 1;
  width: 100%;
  max-width: ${({ isbig }) => (isbig ? "1000px" : "1300px")};
  margin-right: auto;
  margin-left: auto;
  margin: ${({ isCentered }) => (isCentered ? "100px auto" : "0px auto")};
  padding: 10px;
  padding-top: 0px;
  box-shadow: ${({ isBoxShadow }) =>
    isBoxShadow ? "0px 12px 32px #383B470F" : "0"};
  height: ${({ isSign }) => (isSign ? "100vh" : "")};
  /* padding-right: 10px;
  padding-left: 10px; */
  @media screen and (max-width: 991px) {
    padding-right: ${({ isPaddingMobileRight }) =>
      isPaddingMobileRight ? "12px" : "0px"};
    padding-left: ${({ isPaddingMobileLeft }) =>
      isPaddingMobileLeft ? "12px" : "0px"};
    height: ${({ isSign }) => (isSign ? "" : "")};
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#F50057" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    transform: scale(1.02);
    background-color: ${({ primary }) => (primary ? "#dd2476" : "#4B59F7")};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  background-image: url("http://www.concernsrilanka.com/images/concernsl_logo.jpg");
  background-size: contain;
  object-fit: fill;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -1px;
  max-width: 1000px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (max-width: 991px) {
    width: 100%;
    height: 10vh;
  }
`;

export default GlobalStyle;
