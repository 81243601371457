export const homeObjOne = {
  primary: true,
  lightBg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "Review us",
  headline: "Your review mad us a good choisable platform.",
  description:
    "We provide worldwide access to all countries. If there are any issues, just issue a refund and we will process your request please rate us with choises.",
  buttonLabel: "Rate us :)",
  imgStart: "",
  img: require("../../images/fg.svg"),
  alt: "Credit Card",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "100% Satisfying when you tribute",
  headline: "Tribute by  24/7 from anywhere anytime",
  description:
    "We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime you can access our platform and pays tributes",
  buttonLabel: "Learn More :)",
  imgStart: "",
  img: require("../../images/xxx.svg"),
  alt: "Vault",
};

export const homeObjThree = {
  // lightBg: true,
  // lightText: false,
  // lightTextDesc: false,
  // topLine: "Easy Setup",
  // headline: "Super fast and simple onboarding process",
  // description:
  //   "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  // buttonLabel: "Start Now",
  // imgStart: "start",
  // img: require("../../images/profile.jpg"),
  // alt: "Vault",
  primary: true,
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Why to choose Us ?",
  headline:
    "PassingWay Help you to create your own condolances with multiple choises !",
  description:
    "Our team is wonderful! You can't believe We all stand with you. for your wise choises.",
  buttonLabel: "View Our Plans",
  imgStart: "start",
  img: require("../../images/ff.png"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "DATA ANALYTICS",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};
