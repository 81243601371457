import styled from "styled-components";

export const SivapuranamContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px 0 rgba(2, 3, 6, 0.12);
  margin: 20px 0 0 45px;
  border-radius: 5px;
  flex-direction: column;
  color: ${({ isgreen }) => (isgreen ? "#f50057" : "black")};
`;
export const SivapuranamHeading = styled.span`
  font-size: 1.1rem;
  padding: 2px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  opacity: 0.99;
  /* background-color: #101522; */
  background: linear-gradient(90deg, #005c97 0%, #363795 100%);
  color: white;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const SivapuranamPdf = styled.img`
  text-decoration: none;
  color: #333;
  opacity: 0.5;
  font-size: 1.2rem;
  font-weight: 500;
  transition: ease-in-out 0.1s;
  width: 68%;
  text-align: justify;
  cursor: pointer;
  transform: scale(0.5);
  padding: -20px;

  &:hover {
    transition: ease-in-out 0.1s;
    transform: scale(0.6);
    /* background-color: #101522; */
    color: white;
    opacity: 1;
    border-radius: 3px;
  }
`;

export const DownloadPdf = styled.span`
  color: red;
  font-size: 0.8rem;
  float: right;
`;

export const TimeZone = styled.div`
  background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 10px;
  display: flex;
  /* color: black; */
  float: right;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const ContentName = styled.div`
  font-size: 18px;
`;
