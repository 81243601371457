import styled from "styled-components";

export const ApproachContainer = styled.div`
  /* padding: 100px 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-wrap: wrap;
`;

export const ApproachWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  /* padding: 0 40px; */

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0px;
    grid-gap: 14px;
  }
`;

export const ApproachCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  /* padding: 10px; */
  box-shadow: 10px 42px 32px #383b470f;
  cursor: pointer;
  flex-direction: column;
  /* border: 1px solid tomato; */

  transition: 0.1s ease-in-out;
  &:hover {
    transform: scale(1.01);
    transition: 0.3s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ApproachIcon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  border-radius: 50px;
`;

export const ApproachH1 = styled.h1`
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.1;
  font-weight: 500;
  opacity: 0.6;

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    padding: 10px;
  }
`;

export const ApproachH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 15px;
  font-weight: 100;
  color: white;
  /* opacity: 0.4; */
  /* margin-left: 10px; */
  background: #101522;
  width: 100%;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 5px;
  }
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
`;

export const ApproachP = styled.p`
  font-size: 0.8rem;
  /* text-align: center; */
  /* color: #01bf71; */
  color: #333;
  opacity: 0.7;
  padding: 10px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    padding: 10px;
  }
`;
export const HairlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
