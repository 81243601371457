import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`

    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  
`;
export const Main = styled.div`
  position: absolute;
  height: 3rem;
  padding-right: 0%;
  box-sizing: content-box;
  margin-left: 8%;
  margin: 0px auto;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  width: 100%;
  color: red;
  cursor: pointer;
  max-width: 1000px;
  background: #fff;
  /* border-bottom: 1px solid #ddd; */
  /* border-left: 1px solid #ddd; */
`;
export const TickerContainer = styled.div`
  position: absolute;
  height: 3rem;
  padding-right: 0%;
  box-sizing: content-box;
  margin-left: 8%;
  margin: 0px auto;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 2px;
  width: 100%;
  color: red;
  cursor: pointer;
  /* background: #e6eaf4; */
  /* background: #dd2476; */
  background: #f9f9f9;
  height: 3rem;
  max-width: 1020px;
  /* zoom: 80%; */
`;

export const Ticker = styled.div`
  display: inline-block;
  height: 0.7rem;
  line-height: 3.5rem;

  white-space: nowrap;
  padding-right: 0%;
  box-sizing: content-box;
  padding-left: 100%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ${breatheAnimation};
  animation-name: ${breatheAnimation};
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
`;

export const TickerContent = styled.p`
  display: inline-block;
  padding: 0 1rem;
  font-size: 1.3rem;
  color: #f50057;
  margin-right: 10px;
  font-weight: 100;
`;
