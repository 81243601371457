import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import SideBar from "./SideContainer";
import axios from "axios";
import moment from "moment";
import {
  ScreenContainer,
  MainContainer,
} from "../../components/MainContainer/MainContainer.elelents";
import { ContainerMain, Col, Row, HairlineSeperator, IMGFrame } from "./styled";
import { Container, Button } from "../../globalStyles";
import { DatePicker, Input, Radio, Upload, Modal, Spin } from "antd";
import CKEditor from "ckeditor4-react";

// Images

import BASE_URL from "../../constant/index";
import swal from "sweetalert";
import data from "../../mocks/passingcarddata";
const { TextArea } = Input;

function Index(props) {
  // const isInitialMount = useRef(true);
  //   const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [alldata, setAlldata] = useState();
  const [status, setStatus] = useState(true);
  // returns '4 foot tail'

  // let obj = alldata.find((o) => o._id == props.match.params.id);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("title");
  const [birthdate, SetBirthdate] = useState("2021-12-12");
  const [birthplace, setBirthplace] = useState("birthplace");
  const [citydeceased, setCitydeceased] = useState("citydeceased");
  const [jobtitle, setJobtitle] = useState("jobtitle");
  const [namedeceased, setnameDeceased] = useState("name");
  const [age, setAge] = useState("age");
  const [deathdate, setDeathdate] = useState("2021-12-12");
  const [deathplace, setDeathplace] = useState("deathplace");
  const [countrylived, setCountrylived] = useState("countrylived");
  const [lastemployment, setLastemployment] = useState("last employent");
  const [description, setDescription] = useState("<p>description</p>");

  // Nect kin of details
  const [kname, setKname] = useState("kname");
  const [kphone, setKphone] = useState("kphone");
  const [kcity, setKcity] = useState("kcity");
  const [kviewlocation, setKviewlocation] = useState("kviewlocation");
  const [kceremonylocation, setKceremonylocation] =
    useState("kceremonylocation");
  const [kfamilylocation, setKfamilylocation] = useState("kfamily location");
  const [krelationship, setKrelationship] = useState("krelationship");
  const [kemail, setKemail] = useState("kemail");
  const [kpostalcode, setKpostalcode] = useState("kpostalcode");
  const [kviewingdatetime, setKviewdatetime] = useState("viewdatetime");
  const [kceremonydatetime, setKceremonydatetime] =
    useState("kceremonydatetime");
  const [kburialdateandtime, setKburialdateandtime] =
    useState("kburialdateandtime");
  const [kburiallocation, setKburiallocation] = useState("kburiallocation");

  // Informer details
  const [iname, setIname] = useState("iname");
  const [iemail, setIemail] = useState("iemail");
  const [iphone, setIphone] = useState("iphone");
  const [iaddress, setIaddress] = useState("iaddress");

  const finalupdate_id = props.match.params.id;
  // Update data
  const UpdateFunctionData = () => {
    setLoading(true);

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({
        _id: finalupdate_id,
        title: title,
        dateofbirth: birthdate,
        birthplace: birthplace,
        citydeceased: citydeceased,
        jobtitle: jobtitle,
        namedeceased: namedeceased,
        dateofdeceased: deathdate,
        deathplace: deathplace,
        countrylived: countrylived,
        lastemployment: lastemployment,
        age: age,
        description: description,

        // KIN OFDETAULS
        kfullname: kname,
        kphone: kphone,
        kcity: kcity,
        kviewlocation: kviewlocation,
        kceremonylocation: kceremonylocation,
        kfamilylocation: kfamilylocation,
        krelationship: krelationship,
        kemail: kemail,
        kpostalcode: kpostalcode,
        kviewingdatetime: kviewingdatetime,
        kceremonydatetime: kceremonydatetime,
        kburialdateandtime: kburialdateandtime,
        kburiallocation: kburiallocation,

        //INFORMER DETAILS
        iname: iname,
        imobile: iphone,
        iemail: iemail,
        iaddress: iaddress,
      }),
    };

    // Data from hooks state value
    fetch(`${BASE_URL}/updateobitury`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          swal({
            timer: 3000,
            title: "Error",
            text: data.error,
            icon: "error",
            button: false,
          });
          setLoading(false);
        } else {
          swal({
            timer: 3000,
            title: "Success",
            text: "sucessfully posted update on your notice !",
            icon: "success",
            button: "Okey",
            button: false,
          });
          setLoading(false);
          window.scrollTo(0, 0);
          history.push("/createnotice");
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // refresh data

  const refreshData = () => {
    setLoading(true);
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/updateu/${props.match.params.id}`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data);

      setAlldata(response.data);
      setTitle(response.data.title);
      SetBirthdate(response.data.dateofbirth);
      setBirthplace(response.data.birthplace);
      setCitydeceased(response.data.citydeceased);
      setJobtitle(response.data.jobtitle);
      setnameDeceased(response.data.namedeceased);
      setAge(response.data.age);
      setDeathdate(response.data.dateofdeceased);
      setDeathplace(response.data.deathplace);
      setCountrylived(response.data.countrylived);
      setLastemployment(response.data.lastemployment);
      setDescription(response.data.description);

      setKname(response.data.kfullname);
      setKphone(response.data.kphone);
      setKcity(response.data.kcity);
      setKviewlocation(response.data.kviewlocation);
      setKceremonylocation(response.data.kceremonylocation);
      setKfamilylocation(response.data.kfamilylocation);
      setKrelationship(response.data.krelationship);
      setKemail(response.data.kemail);
      setKpostalcode(response.data.kpostalcode);
      setKviewdatetime(response.data.kviewingdatetime);
      setKceremonydatetime(response.data.kceremonydatetime);
      setKburialdateandtime(response.data.kburialdateandtime);
      setKburiallocation(response.data.kburiallocation);

      setIname(response.data.iname);
      setIphone(response.data.imobile);
      setIemail(response.data.iemail);
      setIaddress(response.data.iaddress);

      setLoading(false);

      // setAlldata(response.data.find((o) => o._id == props.match.params.id));
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  // setTitle(obj.title);

  return (
    <>
      {/* <Loader /> */}
      <Container isZoom>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" tip="Loading..."></Spin>
          </div>
        ) : (
          <ScreenContainer style={{ margin: 0 }} isdisplay={true}>
            <SideBar />

            <MainContainer
              style={{
                display: "flex",
                flex: 1,
                background: "#fff",
                marginTop: -10,
              }}
            >
              <>
                {/* PROFILE */}
                <h2
                  style={{
                    fontWeight: 100,
                    marginBottom: 20,
                    color: "black",
                    fontWeight: 700,
                  }}
                >
                  UPDATE NOTICE FORM
                </h2>
                <p
                  style={{
                    color: "white",
                    backgroundColor: "crimson",
                    padding: 5,
                    fontSize: 18,
                  }}
                >
                  ** Please note that if you miss any field without any values
                  in it that updated to the main panel as null..!
                </p>

                <h2
                  style={{
                    fontWeight: 700,
                    marginBottom: 20,
                    color: "blue",
                  }}
                >
                  Deceased Details
                </h2>
                <HairlineSeperator />

                <form>
                  <ContainerMain>
                    <Col>
                      {/* TITLE */}

                      <div>
                        <label>Deceased Title </label>
                        <div className="select">
                          <select
                            name="nametitle"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                          >
                            <option value={title}>{title}</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Miss">Miss</option>
                          </select>
                        </div>

                        {title == "" ? (
                          <p className="errorMsg">Please fill selections</p>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* DATE OF BIRTH */}

                      <div>
                        <label>Date of Birth </label>

                        <DatePicker
                          required
                          onChange={(value, dateString) =>
                            SetBirthdate(dateString)
                          }
                          value={moment(birthdate)}
                          size="large"
                          style={inputStyles}
                        />

                        {birthdate === "" ? (
                          <p className="errorMsg">
                            * Date of birth is required
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* BIRTH PLACE */}

                      <div>
                        <label>Birth place </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="birthplace"
                          value={birthplace}
                          onChange={(e) => setBirthplace(e.target.value)}
                        />

                        {birthplace && birthplace.type === "required" && (
                          <p className="errorMsg">
                            * Place of birth is required.
                          </p>
                        )}
                      </div>

                      {/* CITY */}

                      <div>
                        <label>City Deceased person </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={citydeceased}
                          onChange={(e) => setCitydeceased(e.target.value)}
                        />

                        {citydeceased === "" && (
                          <p className="errorMsg">
                            City of person is required.
                          </p>
                        )}
                      </div>

                      {/* JOB TITLE */}

                      <div>
                        <label>Job Title </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={jobtitle}
                          onChange={(e) => setJobtitle(e.target.value)}
                        />

                        {jobtitle === "" && (
                          <p className="errorMsg">
                            Job title of person is required.
                          </p>
                        )}
                      </div>

                      {/* AGE */}
                      <div>
                        <label>Age </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                        />

                        {age === "" && (
                          <p className="errorMsg">Age is required.</p>
                        )}
                      </div>
                    </Col>
                    <Col>
                      {/* NAME */}
                      <div>
                        <label>Name of Deceased person </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={namedeceased}
                          onChange={(e) => setnameDeceased(e.target.value)}
                        />

                        {namedeceased === "" && (
                          <p className="errorMsg">
                            Fullname of person is required.
                          </p>
                        )}
                      </div>
                      {/* DATE OF DECEASED */}
                      <div>
                        <label>Date of Deceased </label>
                        <DatePicker
                          required
                          onChange={(value, dateString) =>
                            setDeathdate(dateString)
                          }
                          value={moment(deathdate)}
                          size="large"
                          style={inputStyles}
                        />

                        {deathdate === "" ? (
                          <p className="errorMsg">
                            * Date of birth is required
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* DEATH PLACE */}
                      <div>
                        <label>Death Place </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={deathplace}
                          onChange={(e) => setDeathplace(e.target.value)}
                        />

                        {deathplace === "" && (
                          <p className="errorMsg">
                            place of death is required.
                          </p>
                        )}
                      </div>
                      {/* COUNTRY */}
                      <div>
                        <label>Country lived </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={countrylived}
                          onChange={(e) => setCountrylived(e.target.value)}
                        />

                        {countrylived === "" && (
                          <p className="errorMsg">Country lived is required.</p>
                        )}
                      </div>
                      {/* LAST EMPLOYMENT */}
                      <div>
                        <label>Last employment </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={lastemployment}
                          onChange={(e) => setLastemployment(e.target.value)}
                        />
                        {lastemployment === "" && (
                          <p className="errorMsg">
                            Last employment is required.
                          </p>
                        )}
                      </div>
                    </Col>
                  </ContainerMain>

                  <Col>
                    <div
                      style={{
                        width: "905px",
                      }}
                    >
                      <label>Detail Description</label>
                      <CKEditor
                        data={description}
                        onChange={(evt) => setDescription(evt.editor.getData())}
                      />
                    </div>
                  </Col>
                  {/* NEXT KIN OF DETAILS ::::::::::: */}
                  <h2
                    style={{
                      fontWeight: 700,
                      marginTop: 20,
                      marginBottom: 0,
                      color: "blue",
                    }}
                  >
                    Next Kin Of Details
                  </h2>
                  <HairlineSeperator style={{ marginTop: 30 }} />
                  <ContainerMain>
                    <Col>
                      {/* NEXT KIN OF Full NAME*/}
                      <div>
                        <label>Full name </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kname}
                          onChange={(e) => setKname(e.target.value)}
                        />

                        {kname === "required" && (
                          <p className="errorMsg">Full name is required.</p>
                        )}
                      </div>

                      {/* NEXT KIN OF PHONE*/}
                      <div>
                        <label>Phone number </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kphone}
                          onChange={(e) => setKphone(e.target.value)}
                        />

                        {kphone === "required" && (
                          <p className="errorMsg">Phone number is required.</p>
                        )}
                      </div>

                      {/* NEXT KIN OF CITY*/}
                      <div>
                        <label>City </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kcity}
                          onChange={(e) => setKcity(e.target.value)}
                        />

                        {kcity === "required" && (
                          <p className="errorMsg">
                            Kin of details city is required.
                          </p>
                        )}
                      </div>
                      {/* NEXT KIN OF Viewing location*/}
                      <div>
                        <label>Viewing Location </label>
                        <TextArea
                          style={inputStyles}
                          size="large"
                          value={kviewlocation}
                          onChange={(e) => setKviewlocation(e.target.value)}
                        />

                        {kviewlocation === "required" && (
                          <p className="errorMsg">View location is required.</p>
                        )}
                      </div>

                      {/* CREMATION LOACTION */}

                      <div>
                        <label>Ceremony Location </label>
                        <TextArea
                          style={inputStyles}
                          size="large"
                          value={kceremonylocation}
                          onChange={(e) => setKceremonylocation(e.target.value)}
                        />

                        {kceremonylocation === "required" && (
                          <p className="errorMsg">
                            ceremony location is required.
                          </p>
                        )}
                      </div>

                      {/* FAMILY LOACTION */}

                      <div>
                        <label>Family Location </label>
                        <TextArea
                          style={inputStyles}
                          size="large"
                          value={kfamilylocation}
                          onChange={(e) => setKfamilylocation(e.target.value)}
                        />

                        {kfamilylocation === "required" && (
                          <p className="errorMsg">
                            family location is required.
                          </p>
                        )}
                      </div>
                      <div>
                        <label>Burial / cremation location </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kburiallocation}
                          onChange={(e) => setKburiallocation(e.target.value)}
                        />

                        {kburiallocation === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Burial / cremation location is required.
                          </p>
                        )}
                      </div>
                    </Col>{" "}
                    <Col>
                      {/* RELATIONSHIP OF DECESED*/}
                      <div>
                        <label>Relationship of deceased </label>
                        <TextArea
                          style={inputStyles}
                          size="large"
                          value={krelationship}
                          onChange={(e) => setKrelationship(e.target.value)}
                        />

                        {krelationship === "required" && (
                          <p className="errorMsg">
                            {" "}
                            relationship of deceased is required.
                          </p>
                        )}
                      </div>

                      {/* NEXT KIN OF Email*/}
                      <div>
                        <label>Email </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kemail}
                          onChange={(e) => setKemail(e.target.value)}
                        />

                        {kemail === "required" && (
                          <p className="errorMsg">
                            {" "}
                            email address is required.
                          </p>
                        )}
                      </div>
                      {/* NEXT KIN OF ZipCode*/}
                      <div>
                        <label>Postal Code </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kpostalcode}
                          onChange={(e) => setKpostalcode(e.target.value)}
                        />

                        {kpostalcode === "required" && (
                          <p className="errorMsg"> postalcode is required.</p>
                        )}
                      </div>

                      {/* VIEWING DATE */}

                      <div>
                        <label>Viewing Date and time </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kviewingdatetime}
                          onChange={(e) => setKviewdatetime(e.target.value)}
                        />

                        {kviewingdatetime === "required" && (
                          <p className="errorMsg">
                            {" "}
                            View date and time is required.
                          </p>
                        )}
                      </div>

                      {/* CEREMONY DATE TIME */}

                      <div>
                        <label>Ceremony Date and time </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kceremonydatetime}
                          onChange={(e) => setKceremonydatetime(e.target.value)}
                        />

                        {kceremonydatetime === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Ceremony date and time is required.
                          </p>
                        )}
                      </div>
                      <div>
                        <label>Burial / cremation Date and time </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={kburialdateandtime}
                          onChange={(e) =>
                            setKburialdateandtime(e.target.value)
                          }
                        />

                        {kburialdateandtime === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Burial / cremation date and time is required.
                          </p>
                        )}
                      </div>
                    </Col>
                  </ContainerMain>

                  {/* INFORMER DETAILS ::::::::::: */}
                  <h2
                    style={{
                      fontWeight: 700,
                      marginTop: 20,
                      marginBottom: 0,
                      color: "blue",
                    }}
                  >
                    Informer Details
                  </h2>
                  <HairlineSeperator style={{ marginTop: 30 }} />
                  <ContainerMain>
                    <Col>
                      {" "}
                      {/* INFORMER NAME */}
                      <div>
                        <label>Informer fullname </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={iname}
                          onChange={(e) => setIname(e.target.value)}
                        />

                        {iname === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Informer full name is required.
                          </p>
                        )}
                      </div>
                      {/* INFORMER Mobile */}
                      <div>
                        <label>Informer Email </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={iemail}
                          onChange={(e) => setIemail(e.target.value)}
                        />

                        {iemail === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Informer email is required.
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col>
                      {" "}
                      {/* INFORMER Mobile */}
                      <div>
                        <label>Informer mobile </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={iphone}
                          onChange={(e) => setIphone(e.target.value)}
                        />

                        {iphone === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Informer phone number is required.
                          </p>
                        )}
                      </div>
                      {/* ADDRESS */}
                      {/* INFORMER Mobile */}
                      <div>
                        <label>Informer address </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          value={iaddress}
                          onChange={(e) => setIaddress(e.target.value)}
                        />

                        {iaddress === "required" && (
                          <p className="errorMsg">
                            {" "}
                            Informer address is required.
                          </p>
                        )}
                      </div>
                    </Col>
                  </ContainerMain>

                  {/* BUTTON SUBMIT */}
                  <div>
                    <Button
                      onClick={() => UpdateFunctionData()}
                      primary
                      style={{ width: 400, marginTop: 20 }}
                    >
                      Update Details..
                    </Button>
                  </div>
                </form>
              </>
            </MainContainer>
          </ScreenContainer>
        )}
      </Container>
    </>
  );
}

const inputStyles = {
  width: 400,
  // marginBottom: 7,
  padding: 10,
  color: "blue",
};
export default Index;
