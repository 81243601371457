import x from "../images/x.svg";
import xx from "../images/xxxx.svg";
import xxx from "../images/xxxxx.svg";

const Data = [
  {
    id: 1,
    icon: x,
    heading: "Remembrance Notice",
    content:
      "To remember the passing of your loved one perhaps visiting their grave and laying flowers, Often it is supportive to send a remembrance notice on a ripbook to let them know you are also thinking of them. If you are struggling with what to say in that remembrance, or you want to write .",
  },
  {
    id: 2,
    icon: xx,
    heading: "Obituary Notice",
    content:
      "An obituary acts as a notice of death to friends and family of the deceased.When you publish an obituary on PASSINGWAY, it's easy to share that story and funeral details with the world within a few minutes and all on your own schedule. You can post the link anywhere and easily email it to friends and family. ",
  },
  {
    id: 3,
    icon: xxx,
    heading: "Tribute",
    content:
      "A tribute is wealth, often in kind, that a person gives to another person as a sign of respect and one of the most important ways you can personalize a tribute is through a ripbook tribute notice. Creating a tribute is an easy process and we do it for you in a few minutes. Call us now",
  },
];
export default Data;
