import styled from "styled-components";

export const ContainerMain = styled.div`
  display: flex;
`;
export const Col = styled.div`
  flex-direction: column;
  flex-basis: 100%;
`;
export const Row = styled.div`
  flex-direction: row;
  flex-basis: 100%;
`;

export const HairlineSeperator = styled.div`
  background-color: gray;
  padding: 0.3px;
  border-radius: 50px;
  opacity: 0.3;
  margin-bottom: 20px;
`;

export const IMGFrame = styled.img`
  width: 160px;
  height: 200px;
`;
