import styled from "styled-components";

export const CalanderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 1px 6px 0 rgba(2, 3, 6, 0.12); */
  margin: 0 0 0 45px;
  border-radius: 20px;
`;
