import React, { useEffect, useState } from "react";
import { Button, Container } from "../../globalStyles";
import { Row, Wrapper, Col, Background, Form } from "./Contact.elements";
import swal from "sweetalert";
import Loader from "react-spinners/PropagateLoader";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Input } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { Footer } from "../../components";
import BASE_URL from "../../constant";
const { TextArea } = Input;

function SignUp(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // HOOk form check all validation plates (main function)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const PostContactMessage = (finalData) => {
    setLoading(true);
    const finaldata = new FormData();

    // BASIC DETAILS

    finaldata.append("name", finalData.name);
    finaldata.append("phone", finalData.phone);
    finaldata.append("email", finalData.email);
    finaldata.append("message", finalData.message);

    axios({
      method: "post",
      url: `${BASE_URL}/sendmessage`,
      data: {
        name: finalData.name,
        phone: finalData.phone,
        email: finalData.email,
        message: finalData.message,
      },
    })
      .then((apidata) => {
        if (apidata.error) {
          swal({
            timer: 3000,
            title: "Error",
            text: "Not able to send message..",
            icon: "error",
            button: false,
          });
        } else {
          swal({
            timer: 3000,
            title: "Success",
            text: "We reciver your message, Thank you !",
            icon: "success",
            button: "Okey",
            button: false,
          });

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        style={{
          margin: 30,
          display: "flex",
        }}
      >
        <Container
          isSign={true}
          isCentered
          style={{
            borderRadius: 10,
            padding: 20,
            // height: "100%",
          }}
        >
          <Wrapper>
            <Row>
              <Background />

              <Form>
                <Col>
                  {loading ? (
                    <div
                      style={{
                        // margin: 30,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh",
                      }}
                    >
                      <Loader size={14} color="#dd2476" /> <br />
                    </div>
                  ) : (
                    <>
                      <h1
                        style={{
                          color: "#dd2476",
                          fontSize: 21,
                          // marginTop: -30,
                          marginBottom: 5,
                        }}
                      >
                        Please contact us you can access us 24/7 ..
                      </h1>

                      <form onSubmit={handleSubmit(PostContactMessage)}>
                        <div className="form-control ">
                          <label>Name</label>
                          <Input
                            style={inputStyle}
                            type="text"
                            name="name"
                            placeholder="Enter your name .."
                            {...register("name", {
                              required: true,
                            })}
                          />

                          {errors.name && errors.name.type === "required" && (
                            <p className="errorMsg">Name is required.</p>
                          )}
                        </div>
                        <div className="form-control ">
                          <label>Phone</label>
                          <Input
                            style={inputStyle}
                            type="text"
                            name="phone"
                            placeholder="Enter your Phone .."
                            {...register("phone", {
                              required: true,
                            })}
                          />

                          {errors.phone && errors.phone.type === "required" && (
                            <p className="errorMsg">Phone is required.</p>
                          )}
                        </div>
                        <div className="form-control ">
                          <label>Email</label>
                          <Input
                            style={inputStyle}
                            type="text"
                            name="email"
                            placeholder="email address as username .."
                            {...register("email", {
                              required: true,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                          />

                          {errors.email && errors.email.type === "required" && (
                            <p className="errorMsg">Email is required.</p>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <p className="errorMsg">Email is not valid.</p>
                          )}
                        </div>
                        <div className="form-control">
                          <label>Message to say</label>
                          <textarea
                            style={inputStyle}
                            rows={4}
                            placeholder="Message you want to say"
                            name="message"
                            placeholder="Enter your Message .."
                            {...register("message", {
                              required: true,
                            })}
                          />
                          {errors.message &&
                            errors.message.type === "required" && (
                              <p className="errorMsg">
                                Your Message is required.
                              </p>
                            )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              width: "100%",
                            }}
                            primary
                          >
                            <MessageOutlined /> Send Message
                          </Button>
                          <p style={{ textAlign: "center" }}>Or</p>
                        </div>
                      </form>

                      <Col
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ul>
                          <form
                            action="https://www.paypal.com/donate"
                            method="post"
                            target="_top"
                          >
                            <input
                              type="hidden"
                              name="hosted_button_id"
                              value="VGXPQE74TKZQA"
                            />
                            <input
                              type="image"
                              style={{ width: 100 }}
                              src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif"
                              border="0"
                              name="submit"
                              title="PayPal - The safer, easier way to pay online!"
                              alt="Donate with PayPal button"
                            />
                            <img
                              alt=""
                              border="0"
                              src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
                              width="10"
                              height="10"
                            />
                          </form>
                          {/* <Button btnText="Donate here 💰"></Button> */}
                        </ul>
                      </Col>
                    </>
                  )}
                </Col>
              </Form>
            </Row>
          </Wrapper>
        </Container>
      </div>
      <Footer />
    </>
  );
}

const inputStyle = {
  width: "100%",
};

export default SignUp;
