import styled from "styled-components";

/*
Main Container
Side Container

*/

export const ScreenContainer = styled.div`
  padding: 30px 0;
  background: ${({ lightBg }) => (lightBg ? "#101522" : "#fff")};
  display: flex;
  flex-direction: row;
  flex: 1;
  /* overflow-y: scroll; */
  @media screen and (max-width: 768px) {
    display: ${({ isdisplay }) => (isdisplay ? "none" : "")};
  }
`;

export const MainContainer = styled.div`
  flex-direction: column;
  flex: 0.74;

  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;

export const SideContainer = styled.div`
  flex-direction: column;
  flex: 0.26;
  /* background-color: #101522; */
  color: #fff;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
