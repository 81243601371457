import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import SideBar from "./SideContainer";
import axios from "axios";
import {
  ScreenContainer,
  MainContainer,
} from "../../components/MainContainer/MainContainer.elelents";
import { useForm, Controller } from "react-hook-form";
import { ContainerMain, Col, Row, HairlineSeperator, IMGFrame } from "./styled";
import { Container, Button } from "../../globalStyles";
import { DatePicker, Input, Radio, Upload, Modal, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CKEditor from "ckeditor4-react";
import Uploadimg from "../../images/uplink.png";
import {
  ImageWrapper,
  ImageContainer,
  RipimageParent,
  RipimageChild,
} from "../../components/DetailView/DetailView.elements";
import ReactPlayer from "react-player";
// Images

import BASE_URL from "../../constant/index";
import swal from "sweetalert";

// IMG COLLECTION END
const RadioGroup = Radio.Group;

function Index() {
  //   const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingimg, setLoadingImg] = useState(false);
  const history = useHistory();
  const [birthdate, setBirthdate] = useState("");
  const [deceaseddate, setDeceaseddate] = useState("");
  const [frameimage, setFrameimage] = useState(
    "https://res.cloudinary.com/day2qlngn/image/upload/v1619878803/frames/df_ol5w0m.png"
  );
  const [ripimage, setRipimage] = useState("");
  const [description, setDescription] = useState("");
  const [deceasedimgurl, setDeceasedimgUrl] = useState(Uploadimg);
  const [images, setImages] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [uploadedtxt, setUploadedtxt] = useState("");
  const [audiofile, setAudiofile] = useState("");
  const [baseImage, setBaseImage] = useState(
    "https://pixy.org/download/326607/"
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const clearImage = () => {
    setBaseImage("https://pixy.org/download/326607/");
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onFileChange = (e) => {
    setImages(e.target.files);
  };

  const postDetaileWithImage = () => {
    window.scrollTo(0, 0);
    setLoadingImg(true);
    const data = new FormData();
    data.append("file", ripimage);
    data.append("upload_preset", "employeeapp");
    data.append("cloud_name", "day2qlngn");
    fetch("https://api.cloudinary.com/v1_1/day2qlngn/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setDeceasedimgUrl(data.url);
        setLoadingImg(false);
        setUploaded(true);
        setUploadedtxt("uploaded");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // post Data onclick API
  const postData = (formdata) => {
    // set loading true when form submited
    setLoading(true);
    window.scrollTo(0, 0);

    // IMG COLLECTION SETUP
    const finaldata = new FormData();
    for (const key of Object.keys(images)) {
      finaldata.append("imgCollection", images[key]);
    }

    setFrameimage(formdata.frameimage);
    // BASIC DETAILS
    finaldata.append("title", formdata.nametitle);
    finaldata.append("dateofbirth", birthdate);
    finaldata.append("birthplace", formdata.birthplace);
    finaldata.append("citydeceased", formdata.citydeceased);
    finaldata.append("jobtitle", formdata.jobtitle);
    finaldata.append("namedeceased", formdata.namedeceased);
    finaldata.append("dateofdeceased", deceaseddate);
    finaldata.append("deathplace", formdata.deathplace);
    finaldata.append("countrylived", formdata.countrylived);
    finaldata.append("lastemployment", formdata.lastemployment);
    finaldata.append("deceasedimage", baseImage); //ripimage final set
    finaldata.append("frameimage", frameimage);
    finaldata.append("audiourl", audiofile);
    finaldata.append("age", formdata.age);
    finaldata.append("description", description);
    finaldata.append("contactmobile", formdata.contactmobile);
    finaldata.append("contactname", formdata.contactname);

    // KIN OF DETAILS
    finaldata.append("kfullname", formdata.kfullname);
    finaldata.append("kphone", formdata.kphone);
    finaldata.append("kcity", formdata.kcity);
    finaldata.append("kviewlocation", formdata.kviewlocation);
    finaldata.append("kceremonylocation", formdata.kceremonylocation);
    finaldata.append("kfamilylocation", formdata.kfamilylocation);
    finaldata.append("krelationship", formdata.krelationship);
    finaldata.append("kemail", formdata.kemail);
    finaldata.append("kpostalcode", formdata.kpostalcode);
    finaldata.append("kviewingdatetime", formdata.kviewingdatetime);
    finaldata.append("kceremonydatetime", formdata.kceremonydatetime);
    finaldata.append("kburialdateandtime", formdata.kburialdateandtime);
    finaldata.append("kburiallocation", formdata.kburiallocation);

    // INFORMER DETAILS
    finaldata.append("iname", formdata.iname);
    finaldata.append("imobile", formdata.imobile);
    finaldata.append("iaddress", formdata.iaddress);
    finaldata.append("iemail", formdata.iemail);

    // SET HEADERS AXIOS
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "post",
      url: `${BASE_URL}/createobitury`,
      // url: "/createobitury",
      data: finaldata,
      headers: axiosHeaders,
    })
      .then((apidata) => {
        if (apidata.error) {
          swal({
            timer: 3000,
            title: "Error",
            text: apidata.error,
            icon: "error",
            button: false,
          });
          setLoading(false);
        } else {
          swal({
            timer: 3000,
            title: "Success",
            text: "sucessfully posted new notice !",
            icon: "success",
            button: "Okey",
            button: false,
          });
          setLoading(false);

          history.push("/createnotice");
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <Loader /> */}
      <Container style={{ background: "#fff" }} isZoom>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" tip="Loading..."></Spin>
          </div>
        ) : (
          <ScreenContainer
            style={{ margin: 0, background: "#fff" }}
            isdisplay={true}
          >
            <SideBar />

            <MainContainer
              style={{
                display: "flex",
                flex: 1,
                background: "#fff",
                marginTop: -10,
              }}
            >
              <>
                {/* PROFILE */}

                <h2
                  style={{
                    fontWeight: 100,
                    marginBottom: 20,
                    color: "black",
                    fontWeight: 700,
                  }}
                >
                  NOTICE FORM
                </h2>
                <h2
                  style={{
                    fontWeight: 700,
                    marginBottom: 20,
                    color: "blue",
                  }}
                >
                  Deceased Details
                </h2>
                <HairlineSeperator />
                <Col>
                  {/* PROFILE IMAGE */}
                  {loadingimg ? (
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 50,
                      }}
                    >
                      <Spin size="large" tip="Loading..."></Spin>
                    </Col>
                  ) : (
                    <div className="form-control">
                      <div className="clearfix">
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <label>Deceased Image</label>

                          <ImageWrapper>
                            <ImageContainer>
                              <RipimageParent src={frameimage} />
                              <RipimageChild src={baseImage} />
                            </ImageContainer>
                          </ImageWrapper>

                          {uploaded ? (
                            <></>
                          ) : (
                            <>
                              <input
                                style={{
                                  width: 200,
                                  backgroundColor: "#0052d4",
                                  color: "white",
                                  fontSize: 17,
                                  padding: 10,
                                  cursor: "pointer",
                                  marginLeft: -10,
                                  marginBottom: 5,
                                  width: 200,
                                  borderRadius: 50,
                                }}
                                type="file"
                                // onChange={(e) => {
                                //   setRipimage(e.target.files[0]);
                                // }}
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                                multiple={false}
                                accept="image/*"
                              />
                              <span
                                onClick={() => {
                                  clearImage();
                                }}
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "red",
                                  color: "white",
                                  fontSize: 17,
                                  padding: 10,
                                  cursor: "pointer",
                                  marginLeft: -10,
                                  marginBottom: 5,
                                  width: 200,
                                  borderRadius: 50,
                                }}
                              >
                                <DeleteOutlined /> {` `} Clear
                              </span>
                            </>
                          )}

                          {ripimage ? (
                            <>
                              {uploadedtxt ? (
                                <></>
                              ) : (
                                <></>
                                // <span
                                //   onClick={() => postDetaileWithImage()}
                                //   style={{
                                //     backgroundColor: "green",
                                //     color: "white",
                                //     fontSize: 17,
                                //     padding: 10,
                                //     cursor: "pointer",
                                //     marginTop: 5,
                                //     marginLeft: -10,
                                //     marginBottom: 5,
                                //     width: 200,
                                //   }}
                                // >
                                //   <CloudUploadOutlined /> {` `}
                                //   upload image
                                // </span>
                              )}
                              <span
                                onClick={() => {
                                  setDeceasedimgUrl(Uploadimg);
                                  setUploadedtxt("");
                                  setUploaded(false);
                                }}
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  fontSize: 17,
                                  padding: 10,
                                  cursor: "pointer",
                                  marginLeft: -10,
                                  marginBottom: 5,
                                  width: 200,
                                }}
                              >
                                <DeleteOutlined /> {` `} Clear
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </div>
                    </div>
                  )}
                </Col>
                <HairlineSeperator />
                <form onSubmit={handleSubmit(postData)}>
                  <ContainerMain>
                    <Col>
                      {/* TITLE */}

                      <div>
                        <label>Deceased Title </label>
                        <div className="select">
                          <select
                            name="nametitle"
                            {...register("nametitle", {
                              required: "Please select name title",
                            })}
                          >
                            <option value="">Please select an option</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Miss">Miss</option>
                            <option value="Dr">Dr</option>
                            <option value="Master">Master</option>
                          </select>
                        </div>

                        {errors.nametitle && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {errors.nametitle.message}
                          </p>
                        )}
                      </div>

                      {/* DATE OF BIRTH */}

                      <div>
                        <label>Date of Birth </label>
                        <Controller
                          name="dateofbirth"
                          control={control}
                          defaultValue={null}
                          render={({ value }) => (
                            <DatePicker
                              required
                              onChange={(value, dateString) => {
                                setBirthdate(dateString);
                              }}
                              value={value}
                              size="large"
                              format="DD-MM-YYYY"
                              style={inputStyles}
                            />
                          )}
                        />

                        {birthdate === "" ? (
                          <p className="errorMsg">
                            * Date of birth is required
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* BIRTH PLACE */}

                      <div>
                        <label>Birth place </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="birthplace"
                          placeholder="Place of birth of person rest .."
                          {...register("birthplace", {
                            required: true,
                          })}
                        />

                        {errors.birthplace &&
                          errors.birthplace.type === "required" && (
                            <p className="errorMsg">
                              * Place of birth is required.
                            </p>
                          )}
                      </div>

                      {/* CITY */}

                      <div>
                        <label>City Deceased person </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="citydeceased"
                          placeholder="City of Deceased person .. "
                          {...register("citydeceased", {
                            required: true,
                          })}
                        />

                        {errors.citydeceased &&
                          errors.citydeceased.type === "required" && (
                            <p className="errorMsg">
                              City of person is required.
                            </p>
                          )}
                      </div>

                      {/* JOB TITLE */}

                      <div>
                        <label>Job Title </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="jobtitle"
                          placeholder="City of Deceased person .. "
                          {...register("jobtitle", {
                            required: true,
                          })}
                        />

                        {errors.jobtitle &&
                          errors.jobtitle.type === "required" && (
                            <p className="errorMsg">
                              Job title of person is required.
                            </p>
                          )}
                      </div>

                      {/* CONTACT NAME */}
                      <br />
                      <p style={{ color: "blue", fontSize: 14 }}>
                        * Add only primary contact details here after save you
                        can able to add many
                      </p>
                      <div>
                        <label>Contact Name </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="contactname"
                          placeholder="Contact name .. "
                          {...register("contactname", {
                            required: true,
                          })}
                        />

                        {errors.contactname &&
                          errors.contactname.type === "required" && (
                            <p className="errorMsg">
                              contact name is required.
                            </p>
                          )}
                      </div>

                      <div>
                        <label>Age </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="age"
                          placeholder="age .. "
                          {...register("age", {
                            required: true,
                          })}
                        />

                        {errors.age && errors.age.type === "required" && (
                          <p className="errorMsg">Age is required.</p>
                        )}
                      </div>
                    </Col>
                    <Col>
                      {/* NAME */}
                      <div>
                        <label>Name of Deceased person </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="namedeceased"
                          placeholder="Full Name of Deceased person .. "
                          {...register("namedeceased", {
                            required: true,
                          })}
                        />

                        {errors.namedeceased &&
                          errors.namedeceased.type === "required" && (
                            <p className="errorMsg">
                              Fullname of person is required.
                            </p>
                          )}
                      </div>
                      {/* DATE OF DECEASED */}
                      <div>
                        <label>Date of Deceased </label>

                        <Controller
                          name="datedecased"
                          style={inputStyles}
                          control={control}
                          defaultValue={null}
                          render={({ value }) => (
                            <DatePicker
                              required
                              onChange={(value, dateString) => {
                                setDeceaseddate(dateString);
                              }}
                              value={value}
                              size="large"
                              format="DD-MM-YYYY"
                              style={inputStyles}
                            />
                          )}
                        />

                        {deceaseddate === "" ? (
                          <p className="errorMsg">
                            * Date of deceased is required
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* DEATH PLACE */}
                      <div>
                        <label>Death Place </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="deathplace"
                          style={inputStyles}
                          placeholder="Place of death  .."
                          {...register("deathplace", {
                            required: true,
                          })}
                        />

                        {errors.deathplace &&
                          errors.deathplace.type === "required" && (
                            <p className="errorMsg">
                              Place of death is required.
                            </p>
                          )}
                      </div>
                      {/* COUNTRY */}
                      <div>
                        <label>Country lived </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="countrylived"
                          placeholder="Current Country .. "
                          {...register("countrylived", {
                            required: true,
                          })}
                        />

                        {errors.countrylived &&
                          errors.countrylived.type === "required" && (
                            <p className="errorMsg">Country is required.</p>
                          )}
                      </div>
                      {/* LAST EMPLOYMENT */}
                      <div>
                        <label>Last employment </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="lastemployment"
                          placeholder="City of Deceased person .. "
                          {...register("lastemployment", {
                            required: true,
                          })}
                        />

                        {errors.lastemployment &&
                          errors.lastemployment.type === "required" && (
                            <p className="errorMsg">
                              Last employment of person is required.
                            </p>
                          )}
                      </div>
                      {/* CONTACT MOBILE */}
                      <br />
                      <br />

                      <div>
                        <label>Contact Mobile </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="contactmobile"
                          placeholder="Contact mobile .. "
                          {...register("contactmobile", {
                            required: true,
                          })}
                        />

                        {errors.contactmobile &&
                          errors.contactmobile.type === "required" && (
                            <p className="errorMsg">
                              contact mobile is required.
                            </p>
                          )}
                      </div>
                      <br />

                      <div>
                        <label>Video Url </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="age"
                          placeholder="age .. "
                          {...register("age", {
                            required: true,
                          })}
                        />

                        {errors.age && errors.age.type === "required" && (
                          <p className="errorMsg">Age is required.</p>
                        )}
                      </div>
                    </Col>
                  </ContainerMain>

                  {/* SELECTION OF IMAGE */}
                  <div>
                    <label>Select The frame </label>
                    <RadioGroup
                      name="radiogroup"
                      defaultValue="https://res.cloudinary.com/day2qlngn/image/upload/v1619878804/frames/ddd_gwwwp3.png"
                      value={frameimage}
                      onChange={(e) => {
                        setFrameimage(e.target.value);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 900,
                          overflowX: "scroll",
                        }}
                      >
                        {" "}
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 15,
                            marginLeft: "70%",
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1619878801/frames/frame_ngynrc.png" />
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/image/upload/v1619878801/frames/frame_ngynrc.png">
                            Frame 1
                          </Radio>
                        </Col>
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1619881208/frames/frame_2_itjn0i.png" />
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/image/upload/v1619881208/frames/frame_2_itjn0i.png">
                            Frame 2
                          </Radio>
                        </Col>{" "}
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1619882348/frames/frame_6_wvo012.png" />
                          <br />
                          <Radio
                            value={
                              "https://res.cloudinary.com/day2qlngn/image/upload/v1619882348/frames/frame_6_wvo012.png"
                            }
                          >
                            Frame 3
                          </Radio>
                        </Col>{" "}
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 15,
                            marginLeft: 10,
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1626451359/frames/ddd_vcarqj.png" />
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/image/upload/v1626451359/frames/ddd_vcarqj.png">
                            Frame 4
                          </Radio>
                        </Col>
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <IMGFrame src=" https://res.cloudinary.com/day2qlngn/image/upload/v1626685286/frames/frame_ngynrc_2_du2sm1.png" />
                          <br />
                          <Radio value=" https://res.cloudinary.com/day2qlngn/image/upload/v1626685286/frames/frame_ngynrc_2_du2sm1.png">
                            Frame 5
                          </Radio>
                        </Col>{" "}
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1619878803/frames/df_ol5w0m.png" />
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/image/upload/v1619878803/frames/df_ol5w0m.png">
                            Frame 6
                          </Radio>
                        </Col>{" "}
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1619881846/frames/frame_4_b5fx3j.png" />
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/image/upload/v1619881846/frames/frame_4_b5fx3j.png">
                            Frame 7
                          </Radio>
                        </Col>{" "}
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <IMGFrame src="https://res.cloudinary.com/day2qlngn/image/upload/v1626685451/frames/frame_2_itjn0i_1_td94z0.png" />
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/image/upload/v1626685451/frames/frame_2_itjn0i_1_td94z0.png">
                            Frame 8
                          </Radio>
                        </Col>{" "}
                      </div>
                    </RadioGroup>

                    {frameimage == "" ? (
                      <>
                        <p className="errorMsg">
                          * Please select a frame image
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* SELECTION OF Audio file */}
                  <div>
                    <label>Select The Audio </label>
                    <RadioGroup
                      name="radiogroup"
                      defaultValue="https://res.cloudinary.com/day2qlngn/video/upload/v1626451635/songs/song1_lusuka.3gp"
                      value={audiofile}
                      onChange={(e) => {
                        setAudiofile(e.target.value);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 900,
                          overflowX: "scroll",
                        }}
                      >
                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                            marginLeft: "80%",
                          }}
                        >
                          <div style={{ margin: "0" }}>
                            <ReactPlayer
                              url="https://res.cloudinary.com/day2qlngn/video/upload/v1626451635/songs/song1_lusuka.3gp"
                              width={300}
                              height={30}
                              controls={true}
                              playing={false}
                              loop
                            />
                          </div>
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/video/upload/v1626451635/songs/song1_lusuka.3gp">
                            Song 1
                          </Radio>
                        </Col>

                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <div style={{ margin: "0" }}>
                            <ReactPlayer
                              url="https://res.cloudinary.com/day2qlngn/video/upload/v1626451645/songs/song2_hu9ke2.3gp"
                              width={300}
                              height={30}
                              controls={true}
                              playing={false}
                              loop
                            />
                          </div>
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/video/upload/v1626451645/songs/song2_hu9ke2.3gp">
                            Song 2
                          </Radio>
                        </Col>

                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <div style={{ margin: "0" }}>
                            <ReactPlayer
                              url="https://res.cloudinary.com/day2qlngn/video/upload/v1626451645/songs/song3_r9tqfg.3gp"
                              width={300}
                              height={30}
                              controls={true}
                              playing={false}
                              loop
                            />
                          </div>
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/video/upload/v1626451645/songs/song3_r9tqfg.3gp">
                            Song 3
                          </Radio>
                        </Col>

                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <div style={{ margin: "0" }}>
                            <ReactPlayer
                              url="https://res.cloudinary.com/day2qlngn/video/upload/v1626451651/songs/song4_eg44ii.3gp"
                              width={300}
                              height={30}
                              controls={true}
                              playing={false}
                              loop
                            />
                          </div>
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/video/upload/v1626451651/songs/song4_eg44ii.3gp">
                            Song 4
                          </Radio>
                        </Col>

                        <Col
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            margin: 10,
                          }}
                        >
                          <div style={{ margin: "0" }}>
                            <ReactPlayer
                              url="https://res.cloudinary.com/day2qlngn/video/upload/v1626451655/songs/song5_vhnl78.3gp"
                              width={300}
                              height={30}
                              controls={true}
                              playing={false}
                              loop
                            />
                          </div>
                          <br />
                          <Radio value="https://res.cloudinary.com/day2qlngn/video/upload/v1626451655/songs/song5_vhnl78.3gp">
                            Song 5
                          </Radio>
                        </Col>
                      </div>
                    </RadioGroup>
                  </div>

                  {/* DETAIL DESCRIPTION */}
                  <Col>
                    <div>
                      <label>Detail Description</label>
                      <CKEditor
                        data={description}
                        //   SET DESCRIPTION
                        onChange={(evt) => setDescription(evt.editor.getData())}
                        style={{
                          width: "905px",
                          borderRadius: "2px",
                        }}
                      />
                    </div>
                  </Col>
                  {/* NEXT KIN OF DETAILS ::::::::::: */}
                  <h2
                    style={{
                      fontWeight: 700,
                      marginTop: 20,
                      marginBottom: 0,
                      color: "blue",
                    }}
                  >
                    Next Kin Of Details
                  </h2>
                  <HairlineSeperator style={{ marginTop: 30 }} />
                  <ContainerMain>
                    <Col>
                      {/* NEXT KIN OF Full NAME*/}
                      <div>
                        <label>Full name </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kfullname"
                          placeholder="First name .. "
                          {...register("kfullname", {
                            required: true,
                          })}
                        />

                        {errors.kfullname &&
                          errors.kfullname.type === "required" && (
                            <p className="errorMsg">Full name is required.</p>
                          )}
                      </div>

                      {/* NEXT KIN OF PHONE*/}
                      <div>
                        <label>Phone number </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kphone"
                          placeholder="Phone number .. "
                          {...register("kphone", {
                            required: true,
                          })}
                        />

                        {errors.kphone && errors.kphone.type === "required" && (
                          <p className="errorMsg">Phone number is required.</p>
                        )}
                      </div>

                      {/* NEXT KIN OF CITY*/}
                      <div>
                        <label>City </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kcity"
                          placeholder="City .. "
                          {...register("kcity", {
                            required: true,
                          })}
                        />

                        {errors.kcity && errors.kcity.type === "required" && (
                          <p className="errorMsg">City is required.</p>
                        )}
                      </div>
                      {/* NEXT KIN OF Viewing location*/}
                      <div>
                        <label>Viewing Location </label>
                        <textarea
                          style={inputStyles}
                          size="large"
                          name="kviewlocation"
                          placeholder="viewing location .. "
                          {...register("kviewlocation", {
                            required: true,
                          })}
                        />

                        {errors.kviewlocation &&
                          errors.kviewlocation.type === "required" && (
                            <p className="errorMsg">
                              Viewing location is required.
                            </p>
                          )}
                      </div>

                      {/* CREMATION LOACTION */}

                      <div>
                        <label>Ceremony Location </label>
                        <textarea
                          style={inputStyles}
                          size="large"
                          name="kceremonylocation"
                          placeholder="cremation location .. "
                          {...register("kceremonylocation", {
                            required: true,
                          })}
                        />

                        {errors.kceremonylocation &&
                          errors.kceremonylocation.type === "required" && (
                            <p className="errorMsg">
                              Ceremony location is required.
                            </p>
                          )}
                      </div>

                      {/* FAMILY LOACTION */}

                      <div>
                        <label>Family Location </label>
                        <textarea
                          style={inputStyles}
                          size="large"
                          name="kfamilylocation"
                          placeholder="family location .. "
                          {...register("kfamilylocation", {
                            required: true,
                          })}
                        />

                        {errors.kfamilylocation &&
                          errors.kfamilylocation.type === "required" && (
                            <p className="errorMsg">
                              Family location is required.
                            </p>
                          )}
                      </div>

                      <div>
                        <label>Burial/cremation Location </label>
                        <textarea
                          style={inputStyles}
                          size="large"
                          name="kburiallocation"
                          placeholder="cremation location .. "
                          {...register("kburiallocation", {
                            required: true,
                          })}
                        />

                        {errors.kburiallocation &&
                          errors.kburiallocation.type === "required" && (
                            <p className="errorMsg">
                              Burial or cremation location is required.
                            </p>
                          )}
                      </div>
                    </Col>{" "}
                    <Col>
                      {/* RELATIONSHIP OF DECESED*/}
                      <div>
                        <label>Relationship of deceased </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="krelationship"
                          placeholder="Relationship of deceased .. "
                          {...register("krelationship", {
                            required: true,
                          })}
                        />

                        {errors.krelationship &&
                          errors.krelationship.type === "required" && (
                            <p className="errorMsg">
                              relationship of deceased is required.
                            </p>
                          )}
                      </div>

                      {/* NEXT KIN OF Email*/}
                      <div>
                        <label>Email </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kemail"
                          placeholder="Email address .. "
                          {...register("kemail", {
                            required: true,
                          })}
                        />

                        {errors.kemail && errors.kemail.type === "required" && (
                          <p className="errorMsg">Email address is required.</p>
                        )}
                      </div>
                      {/* NEXT KIN OF ZipCode*/}
                      <div>
                        <label>Postal Code </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kpostalcode"
                          placeholder="postal code .. "
                          {...register("kpostalcode", {
                            required: true,
                          })}
                        />

                        {errors.kpostalcode &&
                          errors.kpostalcode.type === "required" && (
                            <p className="errorMsg">Postal code is required.</p>
                          )}
                      </div>

                      {/* VIEWING DATE */}

                      <div>
                        <label>Viewing Date and time </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kviewingdatetime"
                          placeholder="12-12-2021 9.00AM-10.00AM "
                          {...register("kviewingdatetime", {
                            required: true,
                          })}
                        />

                        {errors.kviewingdatetime &&
                          errors.kviewingdatetime.type === "required" && (
                            <p className="errorMsg">
                              Viewing date and time is required.
                            </p>
                          )}
                      </div>

                      {/* CEREMONY DATE TIME */}

                      <div>
                        <label>Ceremony Date and time </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kceremonydatetime"
                          placeholder="12-12-2021 9.00AM-10.00AM "
                          {...register("kceremonydatetime", {
                            required: true,
                          })}
                        />

                        {errors.kceremonydatetime &&
                          errors.kceremonydatetime.type === "required" && (
                            <p className="errorMsg">
                              Ceremony date and time is required.
                            </p>
                          )}
                      </div>
                      <div>
                        <label>Burial/cremation Date and time </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="kburialdateandtime"
                          placeholder="12-12-2021 9.00AM-10.00AM "
                          {...register("kburialdateandtime", {
                            required: true,
                          })}
                        />

                        {errors.kburialdateandtime &&
                          errors.kburialdateandtime.type === "required" && (
                            <p className="errorMsg">
                              Burial or cremation date and time is required.
                            </p>
                          )}
                      </div>

                      {/* IMG COLLECTION */}

                      <div className="clearfix" style={{ padding: "30px 0px" }}>
                        <label>Upload images </label>

                        <input
                          style={{
                            width: 300,
                            backgroundColor: "tomato",
                            color: "white",
                            fontSize: 17,
                            padding: 10,
                            cursor: "pointer",
                            marginLeft: -10,
                            marginBottom: 5,
                            width: 200,
                          }}
                          type="file"
                          onChange={(e) => onFileChange(e)}
                          accept="image/*"
                          multiple
                        />
                        <p className="errorMsg">
                          please not upload morethan 5 images
                        </p>
                      </div>
                    </Col>
                  </ContainerMain>

                  {/* INFORMER DETAILS ::::::::::: */}
                  <h2
                    style={{
                      fontWeight: 700,
                      marginTop: 20,
                      marginBottom: 0,
                      color: "blue",
                    }}
                  >
                    Informer Details
                  </h2>
                  <HairlineSeperator style={{ marginTop: 30 }} />
                  <ContainerMain>
                    <Col>
                      {" "}
                      {/* INFORMER NAME */}
                      <div>
                        <label>Informer fullname </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="iname"
                          placeholder="informer name .. "
                          {...register("iname", {
                            required: true,
                          })}
                        />

                        {errors.iname && errors.iname.type === "required" && (
                          <p className="errorMsg">
                            Informer fullname is required.
                          </p>
                        )}
                      </div>
                      {/* INFORMER Mobile */}
                      <div>
                        <label>Informer Email </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="iemail"
                          placeholder="informer email .. "
                          {...register("iemail", {
                            required: true,
                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          })}
                        />

                        {errors.iemail && errors.iemail.type === "required" && (
                          <p className="errorMsg">
                            Informer email is required.
                          </p>
                        )}
                        {errors.iemail && errors.iemail.type === "pattern" && (
                          <p className="errorMsg">Email is not valid.</p>
                        )}
                      </div>
                    </Col>
                    <Col>
                      {" "}
                      {/* INFORMER Mobile */}
                      <div>
                        <label>Informer mobile </label>
                        <Input
                          style={inputStyles}
                          size="large"
                          name="imobile"
                          placeholder="informer mobile .. "
                          {...register("imobile", {
                            required: true,
                          })}
                        />

                        {errors.imobile &&
                          errors.imobile.type === "required" && (
                            <p className="errorMsg">
                              Informer mobile is required.
                            </p>
                          )}
                      </div>
                      {/* ADDRESS */}
                      {/* INFORMER Mobile */}
                      <div>
                        <label>Informer address </label>
                        <textarea
                          style={inputStyles}
                          size="large"
                          name="iaddress"
                          placeholder="informer address .. "
                          {...register("iaddress", {
                            required: true,
                          })}
                        />

                        {errors.iaddress &&
                          errors.iaddress.type === "required" && (
                            <p className="errorMsg">
                              Informer address is required.
                            </p>
                          )}
                      </div>
                    </Col>
                  </ContainerMain>

                  {/* BUTTON SUBMIT */}
                  <div>
                    <Button
                      type="submit"
                      primary
                      style={{ width: 400, marginTop: 20 }}
                    >
                      Save Details
                    </Button>
                  </div>
                </form>
              </>
            </MainContainer>
          </ScreenContainer>
        )}
      </Container>
    </>
  );
}

const inputStyles = {
  width: 400,
  // marginBottom: 7,
  padding: 10,
};
export default Index;
