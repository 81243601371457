import React from "react";
import ServiceCards from "./ServiceCard";
import { InfoSection } from "../../components";
import { homeObjOne, homeObjTwo } from "./Data";
import Footer from "../../components/Footer/Footer";
import Pricing from "../../components/PricingCard";
function Services() {
  return (
    <div style={{ backgroundColor: "#f9f9f9" }}>
      <InfoSection {...homeObjTwo} />
      <ServiceCards />
      <Pricing />
      <Footer />
    </div>
  );
}

export default Services;
