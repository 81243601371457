import styled from "styled-components";

import { Link } from "react-router-dom";

export const ObituryCardWrapper = styled(Link)`
  display: flex;
  width: 95%;
  padding: 5px;
  /* padding-top: 4px; */
  /* padding-bottom: 4px; */
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: ease-in-out 0.5s;
  cursor: pointer;
  /* box-shadow: 0 0.5px 6px 0.5px rgba(56, 125, 255, 0.2); */
  border: 1px solid #eee;
  margin: 10px 15px;
  overflow-x: hidden;

  &:hover {
    transition: ease-in-out 0.1s;
  }
`;

export const CardHeader = styled.div`
  padding: 5px;
  width: 100%;
  background-color: #f50057;
  color: #fff;
  text-align: center;

  display: ${({ isHeader }) => (isHeader ? "default" : "none")};
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 16px;
`;

export const TableData = styled.td`
  text-align: left;
  padding: 8px;
  font-size: ${({ isBig }) => (isBig ? "18px" : "14px")};

  width: 200px;
  word-wrap: wrap;

  border: 0.3px solid #f6f6f6;
  font-weight: ${({ bold }) => (bold ? "600" : "300")};
  color: ${({ isRed }) => (isRed ? "crimson" : "black")};
  color: ${({ isGreen }) => (isGreen ? "#363795" : "black")};
  text-align: ${({ isCenter }) => (isCenter ? "center" : "left")};
`;

export const TableRow = styled.tr`
  background-color: ${({ isBgColor }) =>
    isBgColor ? "crimson" : "transparent"};
  &:nth-child(odd) {
    background-color: #dbdcdd;
  }
  &:last-child {
    border: none;
  }
`;

export const ObituryCardContainerImg = styled.div`
  flex-basis: 20%;
  @media screen and (max-width: 960px) {
    flex-basis: 30%;
  }
`;
export const ObituryContainerContent = styled.div`
  flex-basis: 80%;
  @media screen and (max-width: 960px) {
    flex-basis: 70%;
  }
`;

export const PostedWrap = styled.span`
  font-weight: 600;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
export const ObituryImg = styled.img`
  height: ${(isBig) => (isBig ? "89%" : "79%")};
  width: 100%;
  height: ${({ isBigImg }) => (isBigImg ? "225px" : "194px")};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  /* border: 2px solid #ddd; */
`;

export const Hairline = styled.span`
  background-color: #ddd;
  padding: 2px;
  opacity: 0.7;
`;
