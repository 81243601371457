import React, { useState, useEffect } from "react";
import {
  TickerContainer,
  Ticker,
  TickerContent,
  Main,
} from "./Ticker.elements";
import SL from "../../images/sl.svg";
import UK from "../../images/uk.png";
import CAD from "../../images/cad.jpg";
import SW from "../../images/sw.png";
import NR from "../../images/nr.png";
import FR from "../../images/fr.jpg";
import GER from "../../images/ger.png";
import IND from "../../images/ind.jpg";
import USA from "../../images/usa.jpg";

function Index() {
  const data = [
    { url: SL },
    { url: UK },
    { url: CAD },
    { url: SW },
    { url: NR },
    { url: FR },
    { url: GER },
    { url: IND },
    { url: USA },
  ];

  return (
    <>
      <Main style={{ marginBottom: 0, background: "#fff", height: 80 }}>
        <TickerContainer style={{ background: "#fff", height: 100 }}>
          <Ticker style={{ fontWeight: 300 }}>
            We are available at,
            {data.map((item) => (
              <TickerContent>
                {" "}
                <img
                  src={item.url}
                  style={{
                    width: 60,
                    height: 40,
                    padding: 0,
                    borderRadius: 4,
                  }}
                />
              </TickerContent>
            ))}
          </Ticker>
        </TickerContainer>{" "}
      </Main>
    </>
  );
}

export default Index;
