import React, { useEffect, useState } from "react";
import Data from "../../images/datanet.svg";

function Index() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "0% auto",
        height: "100vh",
      }}
    >
      <img src={Data} style={{ width: 400, height: 400 }} />
      <p style={{ color: "gray", fontSize: 20, margin: 30 }}>
        Network is depriciated please check your connect...
      </p>
    </div>
  );
}

export default Index;
