import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { List, Carousel, Spin, Tabs, Form, Input, Modal } from "antd";
import IMG from "../../images/bhjj.png";
import XO1 from "../../images/xo1.png";
import XO2 from "../../images/xo2.png";
import XO3 from "../../images/xo3.png";
import { useForm, Controller } from "react-hook-form";
import WREATH from "../../images/WREATH.jpg";
import swal from "sweetalert";
import ReactPlayer from "react-player";
import ClickIcon from "../../images/vb.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  MainContainer,
  ContentContainer,
  WishesContainer,
  CandleWrapper,
  CandleContainer,
  CandleImg,
  CandleDate,
  ImageWrapper,
  ImageContainer,
  RipimageChild,
  RipimageParent,
  Text,
  Column,
  Row,
  DetailContents,
  HairlineSeperator,
  CarouselItem,
  FlowerImage,
} from "../../components/DetailView/DetailView.elements";

import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";
import Sivapuranam from "../../components/Sivapuranam/Sivapuranam";
import AudioFile from "../../audio/audiohome.mp3";
import Calander from "../../components/Calander/Calander";
import { LoadingOutlined } from "@ant-design/icons";
import Gif from "../../images/can.gif";
import { Button, Container } from "../../globalStyles";
import Headstoneimg from "../../images/headstone.jpg";

import {
  SivapuranamContainer,
  SivapuranamHeading,
  SivapuranamPdf,
} from "../../components/Sivapuranam/Sivapuranam.elements";

import {
  BsBookmarkPlus,
  BsPhone,
  BsExclamationDiamond,
  BsChatQuote,
  BsWindow,
  BsHeart,
  BsHouseDoor,
  BsArrowsExpand,
  BsCheckBox,
} from "react-icons/bs";
import Bock from "../../images/bock.png";
import BASE_URL from "../../constant";
import { Footer } from "../../components";

const { TabPane } = Tabs;
const { TextArea } = Input;

function Index(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const history = useHistory();
  const [alldata, setAlldata] = useState();
  const [loading, setLoading] = useState(false);
  const [imgArray, setimgArray] = useState([]);
  const [tributes, setTributes] = useState([]);
  const [basedata, setBasedata] = useState();
  const [urlmessage, setUrlMessage] = useState();
  const [urlhref, setUrlhref] = useState("");
  const [sidedata, setSidedata] = useState([]);
  const [text, setText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [hint, setHint] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modeltitle, setModeltitle] = useState("");
  const [modelMap, setModelmap] = useState(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15844.558807008396!2d79.86117752781658!3d6.873859774060978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25bb5a5074501%3A0x10abff02ca9d350f!2sColombo%2006%2C%20Colombo!5e0!3m2!1sen!2slk!4v1626682882124!5m2!1sen!2slk"
  );
  const [modelAddress, setModeladdress] = useState("");
  const [modelDate, setModeldate] = useState("");
  const [modelcaption, setModelCaption] = useState("");

  const tributeData = [
    { _id: "1", hint: "We are so sorry for your loss" },
    { _id: "2", hint: "I'm going to miss her, too." },
    { _id: "3", hint: "I hope you feel surrounded by much love." },
    { _id: "4", hint: "Sharing in your sadness as you remember Dan." },
    { _id: "6", hint: "Sending healing prayers and comforting hugs" },
    {
      _id: "7",
      hint: "I was saddened to hear that your grandfather passed away",
    },
    { _id: "8", hint: "With deepest sympathy as you remember." },
  ];
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 54, fontWeight: 100 }} spin />
  );
  // DUMMY DATA
  const [tributedata, setTributeData] = useState([]);
  const keyId = props.match.params.id;

  console.log(imgArray);
  // alert(props.match.params.id);
  // DATA REFRESH
  const reversedtributedata = tributedata.reverse();

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const refreshData = () => {
    setLoading(true);
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/updateu/${props.match.params.id}`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data);
      setAlldata(response.data);
      setimgArray(response.data.imgCollection);
      setBasedata([
        {
          title: response.data.title,
          name: response.data.namedeceased,
          age: response.data.age,
          dateofbirth: response.data.dateofbirth,
          birthplace: response.data.birthplace,
          citydeceased: response.data.citydeceased,
          countrylived: response.data.countrylived,
          jobtitle: response.data.jobtitle,
          dateofdeath: response.data.dateofdeceased,
          deathplace: response.data.deathplace,
          lastemployment: response.data.lastemployment,
          iname: response.data.iname,
          iemail: response.data.iemail,
          iaddress: response.data.iaddress,
          imobile: response.data.imobile,
          kfullname: response.data.kfullname,
          kphone: response.data.kphone,
          krelationship: response.data.krelationship,
          kpostalcode: response.data.kpostalcode,
          kemail: response.data.kemail,
          kcity: response.data.kcity,
          kviewlocation: response.data.kviewlocation,
          kviewingdatetime: response.data.kviewingdatetime,
          kceremonylocation: response.data.kceremonylocation,
          kceremonydatetime: response.data.kceremonydatetime,
          kburialdateandtime: response.data.kburialdateandtime,
          kburiallocation: response.data.kburiallocation,
        },
      ]);
      setTributeData(response.data.comments);
      setLoading(false);

      // setAlldata(response.data.find((o) => o._id == props.match.params.id));
    });
  };

  const PostupdateComment = (finalData) => {
    setLoading(true);
    const finaldata = new FormData();

    // BASIC DETAILS
    finaldata.append("_id", keyId);
    finaldata.append("contactname", finalData.contactname);
    finaldata.append("contactmobile", finalData.contactmobile);

    axios({
      method: "put",
      url: `${BASE_URL}/commentnew`,
      data: {
        _id: keyId,
        text: hint,
        postedBy: finalData.commentname,
      },
    })
      .then((apidata) => {
        if (apidata.error) {
          swal({
            timer: 3000,
            title: "Error",
            text: "Not able to post tribute..",
            icon: "error",
            button: false,
          });
        } else {
          swal({
            timer: 3000,
            title: "Success",
            text: "your tribute is posted, Thank you !",
            icon: "success",
            button: "Okey",
            button: false,
          });
          refreshData();
          setLoading(false);
          setHint("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Refresh Data
  const refreshDataSide = () => {
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/publishedobitury`,
      headers: axiosHeaders,
    }).then((response) => {
      // console.log(response.data.publishedObituries);
      setSidedata(response.data.publishedObituries);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  };

  useEffect(() => {
    refreshData();
    if (tributedata === undefined || tributedata.length == 0) {
      setTributeData([{}]);
    }
    {
      // array empty or does not exist
    }
    refreshDataSide();
  }, []);

  return (
    <>
      <Container>
        {loading ? (
          <div
            style={{
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20%",
              placeItems: "center",
              flexDirection: "column",
            }}
          >
            <Spin size="large" indicator={antIcon} />
            <br />
            <span style={{ color: "gray" }}>Loading...</span>
          </div>
        ) : (
          <>
            <MainContainer>
              <ContentContainer>
                <Column>
                  <Row>
                    <CandleWrapper>
                      <CandleContainer>
                        <CandleImg src={Gif} />
                      </CandleContainer>
                    </CandleWrapper>
                    <ImageWrapper>
                      <ImageContainer>
                        <RipimageParent
                          src={alldata ? alldata.frameimage : "loading"}
                        />
                        <RipimageChild
                          src={alldata ? alldata.deceasedimage : "loading"}
                        />
                      </ImageContainer>
                    </ImageWrapper>
                    <CandleWrapper>
                      <CandleContainer>
                        <CandleImg src={Gif} />
                      </CandleContainer>
                    </CandleWrapper>
                  </Row>

                  <Row
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <CandleDate>
                      Born Date <br />
                      {alldata ? alldata.dateofbirth : "loading"}
                    </CandleDate>
                    <FlowerImage src={IMG} />
                    <CandleDate>
                      Rest Date <br />
                      {alldata ? alldata.dateofdeceased : "loading"}{" "}
                    </CandleDate>
                  </Row>
                  <Text scaleSmall={false} bold style={{ marginBottom: -20 }}>
                    {alldata ? alldata.title : " "} . {` `}
                    {alldata ? alldata.namedeceased : "loading"}
                  </Text>
                </Column>
              </ContentContainer>
              <WishesContainer>
                <div class="containerpic">
                  <img
                    src={Headstoneimg}
                    style={{
                      width: 436,
                      height: 460,
                      marginTop: 40,
                      marginLeft: 0,
                    }}
                  />

                  <Carousel
                    autoplaySpeed={6000}
                    dotPosition={"bottom"}
                    autoplay
                    // effect="fade"
                    class="centeredpic"
                    style={{
                      marginTop: "-70%",
                      width: 200,
                      height: 10,
                      marginLeft: "32%",
                      marginRight: 0,
                    }}
                  >
                    {reversedtributedata.map((item) => (
                      <div style={{ flexDirection: "column" }}>
                        <p
                          style={{
                            color: "white",
                            fontSize: 20,
                            width: 200,
                            height: 200,
                          }}
                        >
                          {item.text}
                          <br />
                          <span style={{ color: "red", marginTop: -30 }}>
                            --{item.postedBy}
                          </span>
                        </p>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </WishesContainer>
            </MainContainer>

            <MainContainer>
              <ContentContainer style={{ background: "white" }}>
                <Column style={{ width: "100%" }}>
                  <Tabs defaultActiveKey="1">
                    <TabPane
                      tab={
                        <span style={{ fontSize: 25 }}>
                          <BsHouseDoor
                            style={{ marginRight: 10, fontSize: 20 }}
                          />
                          Overview
                        </span>
                      }
                      key="1"
                    >
                      {/* <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Deceased person Details
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={basedata}
                        renderItem={(item) => (
                          <>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">Fullname :</span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.title + ": " + item.name}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">City :</span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.citydeceased}
                                  </span>
                                }
                              />
                            </List.Item>

                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Date of birth :
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.dateofbirth}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Date of death :
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.dateofdeath}{" "}
                                  </span>
                                }
                              />
                            </List.Item>

                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Birth place :
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.birthplace}{" "}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Death place :
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.deathplace}{" "}
                                  </span>
                                }
                              />
                            </List.Item>

                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Job Title :
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.jobtitle}{" "}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Last employment:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.lastemployment}{" "}
                                  </span>
                                }
                              />
                            </List.Item>
                          </>
                        )}
                      /> */}
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Brief Description
                      </Text>
                      <HairlineSeperator />
                      <div
                        style={{ flex: 0.7 }}
                        className="descriptionView"
                        dangerouslySetInnerHTML={{
                          __html: alldata ? alldata.description : "loading",
                        }}
                      />
                      <img
                        src={WREATH}
                        style={{ height: 400, width: "100%", borderRadius: 10 }}
                      />
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Informer Details
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={basedata}
                        renderItem={(item) => (
                          <>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Fullname of the informer :
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.iname}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Informer’s E- mail::
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.iemail}
                                  </span>
                                }
                              />
                            </List.Item>

                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Informer’s current address:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.iaddress}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Informer’s Phone no:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.imobile}{" "}
                                  </span>
                                }
                              />
                            </List.Item>
                          </>
                        )}
                      />
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Next of Kin Details
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={basedata}
                        renderItem={(item) => (
                          <>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">Fullname:</span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kfullname}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Relations hip to the deceased:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.krelationship}
                                  </span>
                                }
                              />
                            </List.Item>

                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Phone Number
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kphone}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Email address:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kemail}{" "}
                                  </span>
                                }
                              />
                            </List.Item>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">City:</span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kcity}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Postal code:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kpostalcode}{" "}
                                  </span>
                                }
                              />
                            </List.Item>
                          </>
                        )}
                      />
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Viewing Address and other details.
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={basedata}
                        renderItem={(item) => (
                          <>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Viewing Address:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kviewlocation}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Viewing Date and Time
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kviewingdatetime}
                                  </span>
                                }
                              />
                            </List.Item>
                          </>
                        )}
                      />
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Ceremony Address and other details.
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={basedata}
                        renderItem={(item) => (
                          <>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Ceremony Address:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kceremonylocation}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Ceremony Date and Time
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kceremonydatetime}
                                  </span>
                                }
                              />
                            </List.Item>
                          </>
                        )}
                      />
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Burial/Cremation and other details.
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={basedata}
                        renderItem={(item) => (
                          <>
                            <List.Item>
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Burial/Cremation Address:
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kburiallocation}
                                  </span>
                                }
                              />
                              <List.Item.Meta
                                avatar={<BsBookmarkPlus />}
                                title={
                                  <span style={listStyles}>
                                    <span className="listname">
                                      Burial/Cremation Date and Time
                                    </span>{" "}
                                  </span>
                                }
                                description={
                                  <span className="listdescription">
                                    {item.kburialdateandtime}
                                  </span>
                                }
                              />
                            </List.Item>
                          </>
                        )}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span style={{ fontSize: 25 }}>
                          <BsHeart style={{ marginRight: 10, fontSize: 20 }} />
                          Condolence Messages
                        </span>
                      }
                      key="2"
                    >
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Write Your Message
                      </Text>
                      <HairlineSeperator />
                      <form onSubmit={handleSubmit(PostupdateComment)}>
                        <Form.Item label="Yourname  ">
                          <Input
                            style={{ width: "100%" }}
                            placeholder="Enter Your Name"
                            name="commentname"
                            {...register("commentname", {
                              required: true,
                            })}
                          />

                          {errors.commentname &&
                            errors.commentname.type === "required" && (
                              <p className="errorMsg">
                                * Your Name is required.
                              </p>
                            )}
                        </Form.Item>

                        <Form.Item label="Messages">
                          <Input
                            style={{ width: "100%" }}
                            // rows={3}
                            value={hint}
                            onChange={(e) => {
                              setHint(e.target.value);
                            }}
                            placeholder="Enter Your Message"
                          />
                          {hint ? (
                            <></>
                          ) : (
                            <p className="errorMsg">
                              * Your Message is required.
                            </p>
                          )}
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="submit"
                            primary
                            style={{ width: "100%" }}
                          >
                            Post Condolence Message..
                          </Button>
                        </Form.Item>
                      </form>

                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Select Your Condolence Messages
                      </Text>
                      <HairlineSeperator />
                      <List
                        // style={{ background: "#f7f7f7" }}
                        dataSource={tributeData}
                        renderItem={(item) => (
                          <List.Item
                            key={item._id}
                            onClick={() => {
                              setHint(item.hint);
                            }}
                          >
                            <List.Item.Meta
                              avatar={<BsCheckBox />}
                              title={
                                <span style={listStyles}>
                                  <span className="listname">
                                    {" "}
                                    {item.hint}{" "}
                                  </span>{" "}
                                </span>
                              }
                              description={
                                <span className="listdescription">
                                  {item.text}
                                </span>
                              }
                            ></List.Item.Meta>

                            <img
                              src={ClickIcon}
                              style={{ width: 40, height: 40 }}
                            />
                          </List.Item>
                        )}
                      />
                      {/* /ALL TRIBUTES */}
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        All Condolence Messages
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={reversedtributedata}
                        renderItem={(item) => (
                          <List.Item key={item._id}>
                            <List.Item.Meta
                              avatar={<BsChatQuote />}
                              title={
                                <span style={listStyles}>
                                  <span className="listname">
                                    {" "}
                                    {item.postedBy}{" "}
                                  </span>{" "}
                                </span>
                              }
                              description={
                                <span className="listdescription">
                                  {item.text}
                                </span>
                              }
                            ></List.Item.Meta>

                            <img src={Bock} style={{ width: 60, height: 60 }} />
                          </List.Item>
                        )}
                      />
                    </TabPane>

                    <TabPane
                      tab={
                        <span style={{ fontSize: 25 }}>
                          <BsChatQuote
                            style={{ marginRight: 10, fontSize: 20 }}
                          />
                          Contact
                        </span>
                      }
                      key="3"
                    >
                      <HairlineSeperator />
                      <Text scaleSmall={false} isBlack bold>
                        Contact Details
                      </Text>
                      <HairlineSeperator />
                      <List
                        dataSource={alldata ? alldata.contact : ""}
                        renderItem={(item) => (
                          <List.Item key={item._id}>
                            <List.Item.Meta
                              avatar={<BsExclamationDiamond />}
                              title={
                                <span style={listStyles}>
                                  <span className="listname">
                                    {" "}
                                    Contcat Name: :
                                  </span>{" "}
                                </span>
                              }
                              description={
                                <span className="listdescription">
                                  {item.contactname}
                                </span>
                              }
                            />
                            <List.Item.Meta
                              avatar={<BsPhone />}
                              title={
                                <span style={listStyles}>
                                  <span className="listname">
                                    Contcat Mobile :
                                  </span>{" "}
                                </span>
                              }
                              description={
                                <span className="listdescription">
                                  {item.contactmobile}
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  </Tabs>

                  <HairlineSeperator />
                  <Text scaleSmall={false} isBlack bold>
                    Share Details
                  </Text>
                  <HairlineSeperator />
                  {urlhref ? (
                    <Row>
                      <Input
                        type="text"
                        value={urlhref}
                        style={{ width: "100%" }}
                        placeholder="value"
                        onChange={(event) => setText(event.target.value)}
                      />
                      <CopyToClipboard text={urlhref} onCopy={onCopyText}>
                        <Button style={{ padding: 20 }}>Copy</Button>
                      </CopyToClipboard>
                    </Row>
                  ) : (
                    // <Input style={{ width: "100%" }} value={urlmessage} />
                    ""
                  )}
                  <Row
                    style={{ justifyContent: "center", cursor: "pointer" }}
                    onClick={
                      () => {
                        setUrlhref(window.location.href);
                      }

                      // `http://localhost:3000/fullview/${props.match.params.id}`
                    }
                  >
                    <FacebookIcon
                      size={50}
                      round={true}
                      style={{ margin: 20 }}
                    />
                    <TwitterIcon
                      size={50}
                      round={true}
                      style={{ margin: 20 }}
                    />
                    <ViberIcon size={50} round={true} style={{ margin: 20 }} />
                    <WhatsappIcon
                      size={50}
                      round={true}
                      style={{ margin: 20 }}
                    />
                    <TwitterIcon
                      size={50}
                      round={true}
                      style={{ margin: 20 }}
                    />
                    <EmailIcon size={50} round={true} style={{ margin: 20 }} />
                  </Row>
                </Column>
              </ContentContainer>
              <WishesContainer style={{ background: "white" }}>
                <DetailContents>
                  <div style={{ margin: "20px 0 0 45px" }}>
                    <ReactPlayer
                      url={AudioFile}
                      width={280}
                      height={30}
                      controls={true}
                      playing={true}
                      loop
                    />
                  </div>
                  <SivapuranamContainer style={{ padding: 10 }}>
                    <SivapuranamHeading>Photo Gallery</SivapuranamHeading>
                    <Carousel
                      autoplaySpeed={4000}
                      dotPosition={"bottom"}
                      autoplay
                      style={{ width: 280 }}
                    >
                      {imgArray.map((secondsubitem) => (
                        <div>
                          <img
                            src={secondsubitem}
                            style={{
                              width: 280,
                              height: 280,
                              padding: 10,
                              borderRadius: 15,
                            }}
                          />
                        </div>

                        // <p>{secondsubitem}</p>
                      ))}
                    </Carousel>
                  </SivapuranamContainer>
                  <SivapuranamContainer
                    onClick={() => {
                      setIsModalVisible(true);
                      setModeltitle("Viewing Details");
                      setModelCaption("Viewing");
                      setModeladdress(
                        alldata ? alldata.kviewlocation : "loading"
                      );
                      setModeldate(
                        alldata ? alldata.kviewingdatetime : "loading"
                      );
                    }}
                    style={{ padding: -10 }}
                  >
                    <SivapuranamHeading>Viewing Details</SivapuranamHeading>
                    <SivapuranamPdf src={XO1}></SivapuranamPdf>
                  </SivapuranamContainer>
                  <SivapuranamContainer
                    style={{ padding: -10 }}
                    onClick={() => {
                      setIsModalVisible(true);
                      setModeltitle("Ceremony Details");
                      setModelCaption("Ceremony");
                      setModeladdress(
                        alldata ? alldata.kceremonylocation : "loading"
                      );
                      setModeldate(
                        alldata ? alldata.kceremonydatetime : "loading"
                      );
                    }}
                  >
                    <SivapuranamHeading> Ceremony Details</SivapuranamHeading>
                    <SivapuranamPdf src={XO2}></SivapuranamPdf>
                  </SivapuranamContainer>
                  <SivapuranamContainer style={{ padding: -10 }}>
                    <SivapuranamHeading>Cremation Details</SivapuranamHeading>
                    <SivapuranamPdf
                      onClick={() => {
                        setIsModalVisible(true);
                        setModeltitle("Cremation / burial Details");
                        setModelCaption("Cremation");
                        setModeladdress(
                          alldata ? alldata.kburiallocation : "loading"
                        );
                        setModeldate(
                          alldata ? alldata.kburialdateandtime : "loading"
                        );
                      }}
                      src={XO3}
                    ></SivapuranamPdf>
                  </SivapuranamContainer>
                  <SivapuranamContainer>
                    <ReactPlayer
                      className="youtube"
                      url={"https://www.youtube.com/watch?v=A2l8dGyU_U8"}
                      width={"100%"}
                      height={200}
                      controls={true}
                      style={{ margin: 5 }}
                    />
                  </SivapuranamContainer>
                  <br />
                  <Calander />
                  <br />
                  <SivapuranamContainer>
                    <SivapuranamHeading></SivapuranamHeading>
                    <img
                      src="https://coreldrawdesign.com/resources/previews/preview-civil-service-prepration-advertisement-banner-design-1588431990.jpg"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SivapuranamContainer>{" "}
                  <SivapuranamContainer>
                    <SivapuranamHeading></SivapuranamHeading>
                    <img
                      src="https://coreldrawdesign.com/resources/previews/preview-civil-service-prepration-advertisement-banner-design-1588431990.jpg"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </SivapuranamContainer>
                </DetailContents>
              </WishesContainer>
            </MainContainer>
          </>
        )}
      </Container>
      <Footer />
      <ModelView
        title={modeltitle}
        map={modelMap}
        date={modelDate}
        caption={modelcaption}
        address={modelAddress}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(true)}
        onCancel={() => setIsModalVisible(false)}
      />
    </>
  );
}

const ModelView = ({
  title,
  map,
  address,
  date,
  onOk,
  onCancel,
  visible,
  caption,
}) => {
  return (
    <Modal
      width={1000}
      footer={null}
      title={title}
      visible={visible}
      onOk={() => onOk()}
      onCancel={() => onCancel()}
    >
      <p style={{ fontSize: 20, fontWeight: 100 }}>
        <span style={{ color: "red" }}>{caption} Address</span> {address}
      </p>
      <p style={{ fontSize: 20, fontWeight: 100 }}>
        <span style={{ color: "red" }}>{caption} Date and Time</span> {date}
      </p>

      <iframe
        src={map}
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </Modal>
  );
};

const listStyles = {
  color: "gray",
  fontSize: 19,
  marginRight: 10,
};

export default Index;
