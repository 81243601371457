import styled from "styled-components";

export const ApproachContainer = styled.div`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  zoom: ${({ isZoom }) => (isZoom ? "100%" : "80%")};
`;

export const ApproachWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 40px;
  background: #f9f9f9;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ApproachCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 10px 42px 32px #383b470f;
  cursor: pointer;

  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
    transition: 0.1s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    padding: 50px;
  }
`;

export const ApproachIcon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
`;

export const ApproachH1 = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.1;
  font-weight: 500;
  /* opacity: 0.6; */

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    padding: 10px;
  }
`;

export const ApproachH2 = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 15px;
  font-weight: bold;
  color: #101522;
  /* opacity: 0.7; */
  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 5px;
  }
`;

export const ApproachP = styled.p`
  font-size: 0.9rem;
  text-align: center;
  /* color: #01bf71; */
  color: #333;
  opacity: 0.7;
  padding: 10px;
  line-height: 20px;

  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    padding: 10px;
  }
`;
export const HairlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
