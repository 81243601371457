import styled from "styled-components";
import { FaPhoneSquareAlt, FaPrayingHands } from "react-icons/fa";

export const MainContainer = styled.div`
  /* padding: 30px 0; */
  margin: 20px 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  /* background: black; */
  width: 100%;

  /* border-radius: 20px; */
  /* max-width: 1300px; */

  @media screen and (max-width: 960px) {
    max-width: 100%;
    padding: 0px;
    margin: 0;
    overflow-x: hidden;
  }
`;

// Content Details container
export const ContentContainer = styled.div`
  display: flex;
  flex: 0.65;
  margin-top: 0px;
  margin-bottom: 50px;
  /* background: black; */
  padding: 20px;
  /* border-radius: 40px; */
  /* border-radius: 20px; */
  /* box-shadow: 0 1px 6px 0 rgba(2, 3, 6, 0.12); */
  @media screen and (max-width: 960px) {
    max-width: 100%;
    margin-top: 30px;
    flex: 1;
    padding: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    /* text-align: justify; */
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 960px) {
    padding: 0px;
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    max-width: 100%;
  }
`;

//Tributes Container
export const WishesContainer = styled.div`
  flex: 0.3;
  flex-direction: row;
  /* background: black; */
  position: relative;
  text-align: center;
  /* color: black; */
  margin-bottom: 0;
  max-width: 370px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

// ALL About CANDLE CONTAINER

export const CandleWrapper = styled.div`
  display: flex;
  flex: 0.25;
  flex-direction: row;
`;

export const CandleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 50px;
  margin-top: 100%;

  @media screen and (max-width: 960px) {
    padding: 0px;
  }
`;

export const CandleImg = styled.img`
  height: 150px;
  width: 105px;
  border: none;
  border-radius: 5px;

  @media screen and (max-width: 960px) {
    height: 80px;
    width: 40px;
  }
`;

export const CandleDate = styled.span`
  font-size: 1rem;
  text-align: center;
  color: black;
  font-weight: 600;
  line-height: 25px;
  float: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  flex-wrap: wrap;
  @media screen and (max-width: 960px) {
    font-size: 0.7rem;
    padding: 0px;
  }
`;
export const ImageWrapper = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: row;
`;

export const ImageContainer = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(${(props) => props.backgroundSource});
  background-repeat: no-repeat;
  background-size: 100% 100%; */
`;

export const RipimageParent = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 270px;
  height: 350px;
  z-index: 2;
  margin: 6px 38px;

  @media screen and (max-width: 960px) {
    /* top: 20px; */
    width: 185px;
    height: 240px;
    margin: 0;
  }
`;

export const FlowerImage = styled.img`
  width: 400px;
  height: 60px;
  margin-left: 20px;
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    /* top: 20px; */
    width: 200px;
    height: 30px;
    margin-top: 0px;
  }
`;

export const RipimageChild = styled.img`
  position: absolute;
  width: 180px;
  height: 274px;
  z-index: 1;

  @media screen and (max-width: 960px) {
    /* top */
    width: 120px;
    height: 190px;
    margin: 0;
  }
`;

export const Headstone = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  width: 306px;
  height: 380px;
  z-index: 1;
  margin: 0 auto;
  margin-left: 100px;
  transform: rotateY(10deg);
  /* z-index: -1; */
  margin-top: 100px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const Text = styled.h2`
  text-align: ${({ isCenter }) => (isCenter ? "" : "center")};
  font-size: ${({ scaleSmall }) => (scaleSmall ? "18px" : "23px")};
  color: ${({ isBlack }) => (isBlack ? "crimson" : "crimson")};
  font-weight: ${({ bold }) => (bold ? "600" : "100")};

  @media screen and (max-width: 960px) {
    font-size: ${({ scaleSmall }) => (scaleSmall ? "12px" : "18px")};
  }
`;

export const DetailContents = styled.div`
  padding-left: 50px;
  background: white;
  /* box-shadow: 0 1px 6px 0 rgba(2, 3, 6, 0.12); */
`;

export const HairlineSeperator = styled.div`
  padding: 1.3px;
  background: #333;
  opacity: 0.1;
  width: 100%;
  margin: 10px 0;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dd2476;

  /* border-bottom: 1px solid green; */
  padding: 20px;
  opacity: 0.9;
  background: #f9f9f9;
  flex-direction: row;
  transition: ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    transition: ease-in-out 0.3s;
  }
`;

export const ContactIcon = styled(FaPhoneSquareAlt)`
  font-size: 1.8rem;
  color: #dd2476;
  margin-right: 10px;
`;

export const UserIcon = styled(FaPrayingHands)`
  font-size: 1.8rem;
  color: #dd2476;
  margin-right: 10px;
  margin-left: 30px;
`;

export const CarouselItem = styled.img`
  width: 300px;
  padding: 0px;
  margin: 10px;
  border-radius: 10px;
  height: 330px;
`;
