import React from "react";
import {
  ApproachContainer,
  ApproachH1,
  ApproachWrapper,
  ApproachCard,
  ApproachIcon,
  ApproachH2,
  ApproachP,
  HairlineContainer,
} from "../../components/ServiceCards/ServiceCard.elements";
import Requirements from "../../images/x.svg";
import Conceptualization from "../../images/xx.svg";
import Brainstorming from "../../images/xxx.svg";
import Goal from "../../images/xxxx.svg";
import Deployment from "../../images/xxxxx.svg";
import Meeting from "../../images/xxx.svg";

const Approach = ({ lightLine }) => {
  return (
    <ApproachContainer id="approch">
      <ApproachH1>Our Services</ApproachH1>

      <ApproachWrapper>
        <ApproachCard>
          <ApproachIcon src={Requirements} />
          <ApproachH2>Requirement Analysis</ApproachH2>
          <ApproachP>
            We understand and make sense of the client requirements along with
            the app idea before assessing the next steps.
          </ApproachP>
        </ApproachCard>
        <ApproachCard>
          <ApproachIcon src={Conceptualization} />
          <ApproachH2>Conceptualization!</ApproachH2>
          <ApproachP>
            We conceptualize the navigation based on client requirements, the
            audience expectations and the brand itself.
          </ApproachP>
        </ApproachCard>
        <ApproachCard>
          <ApproachIcon src={Goal} />
          <ApproachH2>Efficient Design</ApproachH2>
          <ApproachP>
            Our team focus on designing web and mobile apps for varied platforms
            balancing aesthetics with features combounded.
          </ApproachP>
        </ApproachCard>
        <ApproachCard>
          <ApproachIcon src={Brainstorming} />
          <ApproachH2>Brainstorming Sessions </ApproachH2>
          <ApproachP>
            Freequent Brainstorming sessions with developer to get the best
            outcome for clients.
          </ApproachP>
        </ApproachCard>
        <ApproachCard>
          <ApproachIcon src={Meeting} />
          <ApproachH2>Freequent Meeting </ApproachH2>
          <ApproachP>
            We are ensure with client's view and we deliver it for them only so
            discussion is the key .
          </ApproachP>
        </ApproachCard>
        <ApproachCard>
          <ApproachIcon src={Deployment} />
          <ApproachH2>Deployment and Maintanance</ApproachH2>
          <ApproachP>
            All deployment and Maintanance services are from our side , yes we
            did this for you !
          </ApproachP>
        </ApproachCard>
      </ApproachWrapper>
    </ApproachContainer>
  );
};

export default Approach;
