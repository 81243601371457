import React, { useEffect, useState } from "react";
import { Button, Container } from "../../globalStyles";

import { ImDatabase } from "react-icons/im";
import { BsSearch, BsClockHistory, BsCloud } from "react-icons/bs";
import {
  ScreenContainer,
  MainContainer,
  SideContainer,
} from "./MainContainer.elelents";

import ReactPlayer from "react-player";

import Calander from "../Calander/Calander";
import Sivapuranam from "../Sivapuranam/Sivapuranam";

import {
  SivapuranamContainer,
  SivapuranamHeading,
  TimeZone,
  ContentName,
} from "../Sivapuranam/Sivapuranam.elements";
import AudioFile from "../../audio/audiohome.mp3";
import { Skeleton, Card, Avatar, Input, Space, Modal } from "antd";
import axios from "axios";
import moment, { now } from "moment";
import SideCard from "../SideCard";
// import { HairlineSeperator } from "../PassingCard/PassingCard.elements";
import BASE_URL from "../../constant";
import ObituryCard from "../ObituryCard/ObituryCard";
import moment1 from "moment-timezone";
import Loader from "react-spinners/PropagateLoader";

const { Meta } = Card;

function Index() {
  const [alldata, setAlldata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [city, setCity] = useState({});
  const [city1, setCity1] = useState({});
  const [city2, setCity2] = useState({});
  const [city3, setCity3] = useState({});
  const [city4, setCity4] = useState({});

  const [countries, setCountries] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);

  var jun = moment1(Date.now());

  // Refresh Data
  const refreshData = () => {
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/publishedobitury`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data.publishedObituries);
      setAlldata(response.data.publishedObituries);
    });
  };

  const renderOne = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/publishedobitury`)
      .then((res) => {
        setCountries(res.data.publishedObituries);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetWeather = (cityname) => {
    const city = cityname;
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=d473959da4760d9e46272a9351ecd1fc`
      )
      .then((res) => {
        setCity(res.data);
      });
  };
  const GetWeather1 = (cityname) => {
    const city = cityname;
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=d473959da4760d9e46272a9351ecd1fc`
      )
      .then((res) => {
        setCity1(res.data);
      });
  };
  const GetWeather2 = (cityname) => {
    const city = cityname;
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=d473959da4760d9e46272a9351ecd1fc`
      )
      .then((res) => {
        setCity2(res.data);
      });
  };
  const GetWeather3 = (cityname) => {
    const city = cityname;
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=d473959da4760d9e46272a9351ecd1fc`
      )
      .then((res) => {
        setCity3(res.data);
      });
  };
  const GetWeather4 = (cityname) => {
    const city = cityname;
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=d473959da4760d9e46272a9351ecd1fc`
      )
      .then((res) => {
        setCity4(res.data);
      });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);

  useEffect(() => {
    renderOne();
    GetWeather("Colombo");
    GetWeather1("Toronto");
    GetWeather2("London");
    GetWeather3("Chennai");
    GetWeather4("Paris");
  }, []);

  useEffect(() => {
    setFilteredCountries(
      countries.filter(
        (item) =>
          item.namedeceased.toLowerCase().includes(search.toLowerCase()) ||
          item.birthplace.toLowerCase().includes(search.toLowerCase()) ||
          item.countrylived.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, countries]);

  return (
    <>
      <Container>
        <ScreenContainer>
          <MainContainer className="majorcard" style={{ overflowY: "scroll" }}>
            <Modal
              title={
                <Input
                  style={{
                    width: "97%",
                  }}
                  placeholder="Search By name , Place of birth , Country ..."
                  size="large"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              }
              centered
              visible={visible}
              onCancel={() => {
                setVisible(false);
                setSearch("");
              }}
              width={1000}
              footer={null}
            >
              {filteredCountries.map((item, idx) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SideCard
                    key={idx}
                    to={`/fullview/${item._id}`}
                    profileImg={item.deceasedimage}
                    name={item.namedeceased}
                    postedOn={item.createdAt}
                    displayTribute={true}
                  />
                </div>
              ))}
            </Modal>
            {alldata.length === 0 ? (
              <div
                style={{
                  width: "97%",
                  height: "40%",
                  marginTop: 50,
                  borderRadius: 10,
                  marginBottom: 20,
                  border: "2px solid #f1f1f1",
                  padding: "20%",
                  color: "#ddd",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <ImDatabase style={{ fontSize: 50 }} />
                <h1 style={{ fontSize: 30, color: "#eee" }}>
                  No Obituries Found
                </h1>
              </div>
            ) : (
              alldata.map((item) =>
                loading ? (
                  <div
                    style={{
                      width: "97%",
                      marginTop: 0,
                      borderRadius: 10,
                      marginBottom: 20,
                      border: "2px solid #f1f1f1",
                      padding: 10,
                    }}
                  >
                    <Skeleton
                      style={{
                        boxShadow: "0 0.5px 6px 0.5px rgba(56, 125, 255, 0.2);",
                      }}
                      loading={loading}
                      avatar
                      active
                      hoverable
                      paragraph={{ rows: 5 }}
                    >
                      <Meta
                        avatar={
                          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        }
                        title="Card title"
                        description="This is the description"
                      />
                    </Skeleton>
                  </div>
                ) : (
                  <ObituryCard
                    isBigImg={false}
                    isHeader={false}
                    to={`/fullview/${item._id}`}
                    key={item._id}
                    ripimage={item.deceasedimage}
                    name={item.namedeceased}
                    dateofborn={item.dateofbirth}
                    dateofdeath={item.dateofdeceased}
                    placeofbirth={item.birthplace}
                    placeofdeath={item.deathplace}
                    frameImg={item.frameimage}
                    postedon={moment(item.createdAt).format("DD-MM-YYYY")}
                    nooftributes={item.comments.length}
                    timestamp={moment(item.createdAt).fromNow(true)}
                  />
                )
              )
            )}
          </MainContainer>

          <SideContainer>
            <div style={{ margin: "20px 0 0 45px" }}>
              <ReactPlayer
                url={AudioFile}
                width={300}
                height={30}
                controls={true}
                playing={true}
                loop
              />
            </div>

            <SivapuranamContainer style={{ marginBottom: 10 }}>
              <Button
                onClick={() => setVisible(true)}
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(90deg, #005c97 0%,#363795 100% )",
                }}
                primary
                fontBig
              >
                <BsSearch style={{ fontSize: 16 }} /> {`    `}
                Search
              </Button>
            </SivapuranamContainer>

            <Calander />
            {/* <SideFilter /> */}
            <SivapuranamContainer>
              <SivapuranamHeading></SivapuranamHeading>
              <ReactPlayer
                className="youtube"
                url={"https://www.youtube.com/watch?v=A2l8dGyU_U8"}
                width={300}
                height={170}
                controls={true}
                style={{ margin: 5 }}
              />
            </SivapuranamContainer>
            <Sivapuranam />
            <SivapuranamContainer>
              <SivapuranamHeading>World Clock's</SivapuranamHeading>
              <TimeZone>
                <ContentName>
                  <BsClockHistory style={{ marginRight: 5 }} />
                  UK :
                </ContentName>
                <ContentName>
                  {jun.tz("Europe/London").format("HH:mm:ss A")}
                </ContentName>
              </TimeZone>
              <TimeZone>
                <ContentName>
                  <BsClockHistory style={{ marginRight: 5 }} />
                  Canada :
                </ContentName>
                <ContentName>
                  {jun.tz("America/Toronto").format("HH:mm:ss A")}
                </ContentName>
              </TimeZone>
              <TimeZone>
                <ContentName>
                  <BsClockHistory style={{ marginRight: 5 }} />
                  India :
                </ContentName>
                <ContentName>
                  {jun.tz("Asia/kolkata").format("HH:mm:ss A")}
                </ContentName>
              </TimeZone>
              {/* <TimeZone>
                <ContentName>
                  <BsClockHistory style={{ marginRight: 5 }} />
                  Germany :
                </ContentName>
                <ContentName>
                  {jun.tz("Europe/Oslo").format("HH:mm:ss A")}
                </ContentName>
              </TimeZone> */}
              <TimeZone>
                <ContentName>
                  <BsClockHistory style={{ marginRight: 5 }} />
                  France :
                </ContentName>
                <ContentName>
                  {jun.tz("Europe/Paris").format("HH:mm:ss A")}
                </ContentName>
              </TimeZone>
              <TimeZone>
                <ContentName>
                  <BsClockHistory style={{ marginRight: 5 }} />
                  Srilanka :
                </ContentName>
                <ContentName>
                  {jun.tz("Asia/Colombo").format("HH:mm:ss A")}
                </ContentName>
              </TimeZone>
            </SivapuranamContainer>
            <SivapuranamContainer isgreen>
              <SivapuranamHeading>World Weather's</SivapuranamHeading>
              <TimeZone>
                <ContentName>
                  <BsCloud style={{ marginRight: 5, fontSize: 17 }} />
                  {city.name ? city.name : "Colombo"}
                </ContentName>
                <ContentName>
                  {city.main ? Math.round(city.main.temp - 273.15) : "29"}
                  °C
                </ContentName>
              </TimeZone>

              <TimeZone>
                <ContentName>
                  <BsCloud style={{ marginRight: 5, fontSize: 17 }} />
                  {city2.name ? city2.name : "London"}
                </ContentName>
                <ContentName>
                  {city2.main ? Math.round(city2.main.temp - 273.15) : "16"}
                  °C
                </ContentName>
              </TimeZone>
              <TimeZone>
                <ContentName>
                  <BsCloud style={{ marginRight: 5, fontSize: 17 }} />
                  {city3.name ? city3.name : "Chennai"}
                </ContentName>
                <ContentName>
                  {city3.main ? Math.round(city3.main.temp - 273.15) : "31"}
                  °C
                </ContentName>
              </TimeZone>
              <TimeZone>
                <ContentName>
                  <BsCloud style={{ marginRight: 5, fontSize: 17 }} />
                  {city1.name ? city1.name : "Toronto"}
                </ContentName>
                <ContentName>
                  {city1.main ? Math.round(city1.main.temp - 273.15) : "17"}
                  °C
                </ContentName>
              </TimeZone>
              <TimeZone>
                <ContentName>
                  <BsCloud style={{ marginRight: 5, fontSize: 17 }} />
                  {city4.name ? city4.name : "Paris"}
                </ContentName>
                <ContentName>
                  {city4.main ? Math.round(city4.main.temp - 273.15) : "23"}
                  °C
                </ContentName>
              </TimeZone>
            </SivapuranamContainer>
            <SivapuranamContainer>
              <SivapuranamHeading></SivapuranamHeading>
              <img
                src="https://i.pinimg.com/736x/45/8c/06/458c06d604b4f01851a42b0207dab8d5.jpg"
                style={{ width: "100%", height: "100%" }}
              />
            </SivapuranamContainer>
            <SivapuranamContainer>
              <SivapuranamHeading></SivapuranamHeading>
              <img
                src="  https://d1csarkz8obe9u.cloudfront.net/posterpreviews/tax-%26-consulting-service-flyer-template-design-cace2cf185322c2d9b25bb31e936acec_screen.jpg?ts=1579873265"
                style={{ width: "100%", height: "100%" }}
              />
            </SivapuranamContainer>
            {/* weather */}
          </SideContainer>
        </ScreenContainer>
      </Container>
    </>
  );
}

export default Index;
