import styled from "styled-components";

/*
TODO

ServiceContainer
ServiceWrapper
ServiceCard
ServiceCardBody
ServiceImage
ServiceName
SeviceDescription
ServiceButton
*/

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    display: none;
  }
  @media screen and (max-width: 480px) {
    height: 1800px;
  }
`;

export const ServiceWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 26px;
  padding: 0 50px;
  margin: 20px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
    margin-top: 20px;
  }
`;

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 400px;
  max-width: 400px;
  /* padding: 10px; */
  cursor: pointer;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  transition: all 0.5s ease-in-out;

  &:hover {
    /* transform: scale(1.05); */
    transition: all 0.5s ease-ease-in-out;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  }
`;

export const ServiceCardBody = styled.div`
  /* padding: 15px; */
  padding: ${({ isBigPadding }) => (isBigPadding ? "30px" : "15px")};
  align-items: center;
  justify-content: center;
  align-self: center;
  place-content: center;
`;

export const ServiceHairline = styled.div`
  padding: 0.8px;
  width: 30%;
  margin: 0 auto;
  height: 0.5px;
  background: #dd2476;
`;

export const ServiceImage = styled.img`
  height: 100px;
  width: 100px;
  align-self: center;
  margin-top: 10px;
`;

export const ServiceName = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  text-align: center;
`;

export const SeviceDescription = styled.p`
  font-size: 0.8rem;
  font-weight: 300;
  color: gray;
  text-align: center;
`;

export const ServiceButton = styled.button`
  /* border-radius: 50px; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  background: ${({ primary }) => (primary ? "#fff" : "#dd2476")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "10px 0px" : "13px 10px")};
  color: #707070;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  font-weight: bold;
  outline: none;
  border: none;
  border-top: 0.5px solid #b8b8b8;
  cursor: pointer;
  width: 100%;
  transition: all 0.1s ease-in;

  &:hover {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 0.1s ease-in;
    border-top: 0.5px solid transparent;

    color: #fff;
    background-color: ${({ primary }) => (primary ? "#dd2476" : "")};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
