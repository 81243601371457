import React, { useEffect, useState, useContext } from "react";
import {
  ScreenContainer,
  MainContainer,
  SideContainer,
} from "../../components/MainContainer/MainContainer.elelents";

import { Link, useHistory, NavLink } from "react-router-dom";
import { UserContext } from "../../App";
import {
  BsHouse,
  BsGear,
  BsPlus,
  BsEye,
  BsAppIndicator,
  BsBookmarkCheck,
  BsChatDots,
  BsAlarmFill,
} from "react-icons/bs";

function SideContainerx() {
  const { state, dispatch } = useContext(UserContext);
  const history = useHistory();

  return (
    <SideContainer
      style={{
        borderRight: "1px solid #ddd",
        marginLeft: -19,
        marginRight: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          // flex: 0.2,
          flexDirection: "column",
          width: 200,
          borderRadius: 0,

          padding: 2,
          position: "sticky",
          background: "transparent",
        }}
      >
        <NavLink to="/createnotice" className="btnside">
          <BsHouse style={styles} /> Dashboard
        </NavLink>

        {/* <NavLink to="/createnotice" className="btnside">
          <BsAlarmFill style={styles} /> All Notices
        </NavLink> */}

        <NavLink to="/addnewu" className="btnside">
          <BsBookmarkCheck style={styles} /> Create Notice
        </NavLink>

        <NavLink to="/profileu" className="btnside">
          <BsGear style={styles} /> Profile
        </NavLink>
        <NavLink to="/feedbacku" className="btnside">
          <BsChatDots style={styles} /> Post Feedback
        </NavLink>

        <span
          style={{ color: "red" }}
          className="btnside"
          onClick={() => {
            localStorage.clear();
            dispatch({ type: "CLEAR" });
            history.push("/signin");
          }}
        >
          <BsAppIndicator style={styles} /> Logout
        </span>
      </div>
    </SideContainer>
  );
}

const styles = {
  marginBottom: -4,
  fontSize: 20,
  marginRight: 5,
  marginLeft: 5,
};

export default SideContainerx;
