const data = [
  {
    country: "Australia ",
    title: "Data 1",
    details: `
Auburn Road,
Auburn
Australia,
T.P:87297472734237492
`,
  },

  {
    country: "Uk",
    title: "  Benisasia Funeral Home INC",
    details: `
1357 Queen
st. west 
T.P:87297472734237492
`,
  },
  {
    country: "India ",
    title: "Funeral Home & Cremation Centre",
    details: `
3280 Shepperd Ave
E.Toronto
T.P:87297472734237492
,

`,
  },
  {
    country: "Germany ",
    title: "ogden funeral house",
    details: `

4164 Shepperd Ave
E.Toronto
Canada,
T.P:87297472734237492


`,
  },
  {
    country: "Canada ",
    title: "The Moddcham Funeral Home",
    details: `

4242 B Kingston Road,
Scarborogh, ontario
Canada,

T.P:87297472734237492

`,
  },
];
export default data;
