import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../App";
import { Link, useHistory } from "react-router-dom";
import { Button, Container } from "../../globalStyles";
import { Row, Wrapper, Col, Background, Form } from "./Signin.elements";
import swal from "sweetalert";
import BASE_URL from "../../constant/index";
import Loader from "react-spinners/PropagateLoader";
import { useForm } from "react-hook-form";

function SignUp(props) {
  const { state, dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // HOOk form check all validation plates (main function)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // OnSubmit Form main function
  const PostData = (data) => {
    // set loading true when form submited
    setLoading(true);
    fetch(`${BASE_URL}/signin`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    })
      .then((res) => res.json())
      .then((apidata) => {
        console.log(apidata);
        if (apidata.error) {
          swal({
            timer: 2000,
            title: "Error",
            text: apidata.error,
            icon: "error",
            button: "Okey",
            button: false,
          });
          setLoading(false);
        } else {
          setLoading(false);
          localStorage.setItem("jwt", apidata.token);
          localStorage.setItem("user", JSON.stringify(apidata.user));
          localStorage.setItem("username", JSON.stringify(apidata.user.name));

          dispatch({ type: "USER", payload: apidata.user });
          swal({
            timer: 3000,
            title: "Good job!",
            text: "Succesfully logged in !",
            icon: "success",
            button: "Okey",
            button: false,
          });

          history.push("/createnotice");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        margin: 30,
        display: "flex",
      }}
    >
      <Container
        isCentered
        style={{
          borderRadius: 10,
          padding: 40,
          height: "80vh",
        }}
      >
        <Wrapper>
          <Row>
            <Background />
            <Form>
              <Col>
                {loading ? (
                  <div
                    style={{
                      margin: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30vh",
                    }}
                  >
                    <Loader size={14} color="#dd2476" /> <br />
                  </div>
                ) : (
                  <>
                    <h1
                      style={{
                        color: "#dd2476",
                        fontSize: 21,
                        marginBottom: 20,
                      }}
                    >
                      Sign in to account ..
                    </h1>

                    <form onSubmit={handleSubmit(PostData)}>
                      <div className="form-control ">
                        <label>Email</label>
                        <input
                          type="text"
                          name="email"
                          placeholder="email address as username .."
                          {...register("email", {
                            required: true,
                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          })}
                        />

                        {errors.email && errors.email.type === "required" && (
                          <p className="errorMsg">Email is required.</p>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <p className="errorMsg">Email is not valid.</p>
                        )}
                      </div>
                      <div className="form-control">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          placeholder="***********"
                          {...register("password", {
                            required: true,
                          })}
                        />
                        {errors.password &&
                          errors.password.type === "required" && (
                            <p className="errorMsg">Password is required.</p>
                          )}
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          type="submit"
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                          primary
                        >
                          Sign in
                        </Button>
                        <Link
                          to="/sign-up"
                          style={{
                            margin: 10,
                            textAlign: "center",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          Don't have have an account ?
                        </Link>
                      </div>
                    </form>
                  </>
                )}
              </Col>
            </Form>
          </Row>
        </Wrapper>
      </Container>
    </div>
  );
}

export default SignUp;
