import React from "react";
import {
  SideCardWrapper,
  SideImg,
  SideCardContainerImg,
  SideContainerContent,
  Col,
  Row,
  Typography,
} from "./Sidecard.elemets";
import moment from "moment";

import { RiHeartPulseFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
function Index({
  profileImg,
  name,
  postedOn,
  to,
  tributes,
  displayTime,
  displayTribute,
}) {
  const history = useHistory();
  return (
    <>
      <SideCardWrapper
        onClick={() => {
          history.push(`${to}`);
          window.location.reload();
        }}
      >
        <Row>
          <SideCardContainerImg>
            <SideImg src={profileImg} />
          </SideCardContainerImg>
          <SideContainerContent>
            <Col>
              <Typography style={{ fontWeight: "bold", color: "#505050" }}>
                {name}
              </Typography>
              <Typography style={{ color: "red" }} isDisplay={displayTime}>
                {" "}
                {moment(postedOn).fromNow(true)} ago
              </Typography>
              <Typography style={{ color: "blue" }} isDisplay={displayTribute}>
                <RiHeartPulseFill /> {tributes} + Tributes
              </Typography>
            </Col>
          </SideContainerContent>
        </Row>
      </SideCardWrapper>
    </>
  );
}

export default Index;
