import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import SideBar from "./SideContainer";
import {
  ScreenContainer,
  MainContainer,
  SideContainer,
} from "../../components/MainContainer/MainContainer.elelents";
// import Card from "../../components/AdminCard/AdminCard";
import Data from "../../mocks/passingcarddata";
import { Button, Container } from "../../globalStyles";
import { Card, Rate } from "antd";
import {
  SlackOutlined,
  BookOutlined,
  CarryOutOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { StarsRating } from "stars-rating-react-hooks";

const config = {
  totalStars: 5,
  initialSelectedValue: 4.5,
  renderFull: (
    <img src="https://img.icons8.com/ios-filled/50/000000/star--v1.png" />
  ),
  renderEmpty: <img src="https://img.icons8.com/ios/50/000000/star--v1.png" />,
  renderHalf: (
    <img src="https://img.icons8.com/ios-filled/50/000000/star-half-empty.png" />
  ),
};

function Index() {
  //   const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  const history = useHistory();
  const [selecting, setSelecting] = useState(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    // s
  }, []);
  return (
    <>
      {/* <Loader /> */}
      <Container isZoom style={{ background: "#fff" }}>
        <ScreenContainer style={{ margin: 0, background: "#fff" }}>
          <SideBar />
          <MainContainer
            style={{
              display: "flex",
              flex: 1,
              background: "#fff",
              marginTop: -10,
              paddingBottom: "30%",
            }}
          >
            {/* DASHBOARD b */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Card
                bordered={true}
                style={{
                  width: 300,
                  margin: 10,
                  borderRadius: 10,
                  padding: 20,
                  // border: "4px solid tomato",
                  textAlign: "center",
                }}
              >
                <h1 style={{ color: "tomato", fontWeight: "bold" }}>
                  <BookOutlined />
                  {`  `}
                  20 Notice
                </h1>
              </Card>

              <Card
                bordered={true}
                style={{
                  width: 300,
                  margin: 10,
                  borderRadius: 10,
                  padding: 20,
                  // border: "4px solid #dd2476",
                  textAlign: "center",
                  // backgroundColor: "tomato",
                  // color: "white",
                }}
              >
                <h1 style={{ color: "#dd2476", fontWeight: "bold" }}>
                  {" "}
                  <SlackOutlined />
                  {`  `}
                  20 Notice
                </h1>
              </Card>

              <Card
                title=""
                bordered={true}
                style={{
                  width: 300,
                  margin: 10,
                  borderRadius: 10,
                  padding: 20,
                  // border: "4px solid green",
                  textAlign: "center",
                  // backgroundColor: "tomato",
                  // color: "white",
                }}
              >
                <h1 style={{ color: "green", fontWeight: "bold" }}>
                  <CarryOutOutlined />
                  {`  `}
                  12 Feedback
                </h1>
              </Card>
            </div>
          </MainContainer>
        </ScreenContainer>
      </Container>
    </>
  );
}

const styles = {
  marginBottom: -4,
  fontSize: 20,
  marginRight: 15,
  marginLeft: 15,
};

export default Index;
