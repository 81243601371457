import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import SideBar from "./SideContainer";
import {
  ScreenContainer,
  MainContainer,
} from "../../components/MainContainer/MainContainer.elelents";
import { useForm } from "react-hook-form";
import { Button, Container } from "../../globalStyles";
import Select from "react-select";
import { Input, Rate, Spin } from "antd";
import axios from "axios";
import swal from "sweetalert";
import BASE_URL from "../../constant";

function Index() {
  //   const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState("2.5");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const PostContactMessage = (finalData) => {
    setLoading(true);
    const finaldata = new FormData();

    // BASIC DETAILS

    finaldata.append("message", finalData.message);

    axios({
      method: "post",
      url: `${BASE_URL}/createfeedback`,
      data: {
        rating: rate,
        title: finalData.message,
      },
    })
      .then((apidata) => {
        if (apidata.error) {
          swal({
            timer: 3000,
            title: "Error",
            text: "Not able to send message..",
            icon: "error",
            button: false,
          });
        } else {
          swal({
            timer: 3000,
            title: "Success",
            text: "We reciver your feedback and raing, Thank you !",
            icon: "success",
            button: "Okey",
            button: false,
          });

          setLoading(false);
          history.push("/createnotice");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <Loader /> */}
      <Container isZoom>
        <ScreenContainer style={{ margin: 0, background: "#fff" }}>
          <SideBar />

          <MainContainer
            style={{
              display: "flex",
              flex: 1,
              background: "#fff",
              height: "80vh",
              marginTop: -10,
            }}
          >
            {loading ? (
              <div
                style={{
                  // margin: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <Spin color="#dd2476" /> <br />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    color: "yellowblack",
                    marginTop: 30,
                    fontWeight: "100",
                    color: "gray",
                    fontSize: 25,
                  }}
                >
                  Please Post your valuable feedback which helps !
                </h1>
                <br />
                <form onSubmit={handleSubmit(PostContactMessage)}>
                  <div style={{ width: 400 }}>
                    <label style={{ fontSize: 15, color: "gray" }}>
                      Rate With stars
                    </label>
                    <Rate
                      allowHalf
                      // defaultValue={4.5}
                      defaultValue={rate}
                      size="large"
                      style={{ color: "tomato", fontSize: 20, width: 400 }}
                      onChange={(value) => setRate(value)}
                    />
                  </div>
                  <div style={{ width: 400 }}>
                    <label style={{ fontSize: 15, color: "gray" }}>
                      Post Feedback for our testimonial.
                    </label>
                    <textarea
                      rows={7}
                      name="message"
                      placeholder="Enter your Feedback here please .."
                      {...register("message", {
                        required: true,
                      })}
                    />
                    {errors.message && errors.message.type === "required" && (
                      <p className="errorMsg">Your Feedback is required.</p>
                    )}
                  </div>

                  <Button type="submit" primary style={{ width: "100%" }}>
                    Submit Feedback..
                  </Button>
                </form>
              </div>
            )}
          </MainContainer>
        </ScreenContainer>
      </Container>
    </>
  );
}

export default Index;
