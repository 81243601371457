import React from "react";

import {
  ObituryCardWrapper,
  ObituryCardContainerImg,
  ObituryContainerContent,
  ObituryImg,
  Table,
  TableData,
  TableRow,
  CardHeader,
  PostedWrap,
} from "./ObituryCard.elements";
import { RiHeartPulseFill } from "react-icons/ri";
import { BsCalendar, BsDiamondHalf } from "react-icons/bs";
function Index({
  name,
  dateofborn,
  dateofdeath,
  placeofbirth,
  placeofdeath,
  postedon,
  ripimage,
  nooftributes,
  timestamp,
  isBigImg,
  isHeader,
  to,
  remember,
}) {
  return (
    <ObituryCardWrapper to={to}>
      <ObituryCardContainerImg>
        <ObituryImg isBigImg={isBigImg} src={ripimage} />
        <div
          style={{
            color: "#f50057",
            textAlign: "center",
            padding: 8,
            fontWeight: "600",
            fontSize: 16,
          }}
        >
          <RiHeartPulseFill style={{ marginTop: 5, marginRight: 10 }} />{" "}
          {nooftributes} Tributes
        </div>
      </ObituryCardContainerImg>

      <ObituryContainerContent>
        <CardHeader isHeader={isHeader}>
          {remember} ம் ஆண்டு நினைவஞ்சலி
        </CardHeader>
        <Table>
          <TableRow>
            <TableData bold>Name</TableData>
            <TableData>{name}</TableData>
          </TableRow>
          <TableRow>
            <TableData bold>Place of Birth</TableData>
            <TableData>{placeofbirth}</TableData>
          </TableRow>
          <TableRow>
            <TableData bold>Place of Death</TableData>
            <TableData> {placeofdeath}</TableData>
          </TableRow>
          <TableRow>
            <TableData bold>Date of Birth</TableData>
            <TableData>{dateofborn}</TableData>
          </TableRow>
          <TableRow>
            <TableData bold>Date of Death</TableData>
            <TableData>{dateofdeath}</TableData>
          </TableRow>
          <TableRow>
            <TableData bold isGreen isCenter>
              <BsCalendar style={{ marginTop: 5, marginRight: 10 }} />
              <PostedWrap>Post-Date:</PostedWrap> {postedon}
            </TableData>
            <TableData bold isGreen isCenter to={to}>
              {" "}
              <BsDiamondHalf style={{ marginTop: 5, marginRight: 10 }} /> Read
              More..
            </TableData>
          </TableRow>
        </Table>
      </ObituryContainerContent>
    </ObituryCardWrapper>
  );
}

export default Index;
