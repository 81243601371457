import React from "react";
import { InfoSection } from "../../components";
import { homeObjOne, homeObjTwo, homeObjThree } from "./Data";
import Data from "../../mocks/serviceData";
import Card from "../../components/ServiceCards/ServiceCard";
import Footer from "../../components/Footer/Footer";
function Products() {
  return (
    <>
      <InfoSection {...homeObjTwo} />
      {/* <Card
        source={Data}
        heading="Services we deliver in short time !"
        isZoom
      /> */}
      {/* <InfoSection {...homeObjOne} /> */}
      <InfoSection {...homeObjThree} />
      <Footer />
    </>
  );
}

export default Products;
