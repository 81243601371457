import React, {
  useEffect,
  useState,
  createContext,
  useReducer,
  useContext,
} from "react";
import GlobalStyle from "./globalStyles";
import Home from "./pages/HomePage/Home";
import axios from "axios";

import Products from "./pages/Products/Products";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Dashboard from "./pages/Dashboard";
// import DetailView from "./components/DetailView/DetailView";
import ServiceCards from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import Poems from "./pages/poems";
// user dashboard
import UserProfile from "./pages/Dashboard/profile";
import AddObitury from "./pages/Dashboard/addnew";
import UpdateObitury from "./pages/Dashboard/updateform";
import Feedback from "./pages/Dashboard/feedback";
import CreateNotice from "./pages/Dashboard/tableview";
import DetailViewUser from "./pages/Dashboard/Detailview";
import DetailViewGlobal from "./pages/Dashboard/DetailviewGlobal";

import Remembrance from "./pages/Remembrance/Remembrance.content";
import Cremotrium from "./pages/Cremotrium";
import FuneralDirectors from "./pages/FuneralDirectors";

import Net from "./pages/Networkcheck";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Navbar, Footer } from "./components";
import { reducer, initialstate } from "./reducers/userReducer";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import BASE_URL from "./constant";
// Exporting user context for global level state management
export const UserContext = createContext();

const Routing = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch({ type: "USER", payload: user });
      history.push("/");
    } else {
      if (history.location.pathname.startsWith("/dashboard"))
        history.push("/signin");
    }

    // ********************************************************************
    //Check high SECURED ADMIN Pages not visible when token set to local storage
    if (!user) {
      //ALL ROUTES
      let dashboard = "/dashboard";
      let addnew = "/addnew";
      let profileu = "/profileu";
      let feedbacku = "/feedbacku";
      let notice = "/createnotice";
      let updateu = "/updateu";

      const validation = () => {
        history.push("/signin");
      };
      // Check Validity routes
      if (history.location.pathname.startsWith(profileu)) {
        validation();
      } else if (history.location.pathname.startsWith(addnew)) {
        validation();
      } else if (history.location.pathname.startsWith(dashboard)) {
        validation();
      } else if (history.location.pathname.startsWith(feedbacku)) {
        validation();
      } else if (history.location.pathname.startsWith(notice)) {
        validation();
      } else if (history.location.pathname.startsWith(updateu)) {
        validation();
      }
    } else {
      history.push("/createnotice");
    }
    // ********************************************************************
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route exact path="/home" component={Home}></Route>
      <Route path="/services" component={ServiceCards} />
      <Route path="/remembrance" component={Remembrance} />
      <Route path="/cremotrium" component={Cremotrium} />
      <Route path="/directors" component={FuneralDirectors} />
      <Route path="/fullview/:id" exact component={DetailViewGlobal} />
      <Route path="/sideview/:id" exact component={DetailViewGlobal} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/signin" component={SignIn} />
      <Route path="/contact" component={Contact} />{" "}
      <Route path="/poems" component={Poems} />
      <Route path="/about" component={Products} />{" "}
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/profileu" component={UserProfile} />
      <Route path="/createnotice" component={CreateNotice} />
      <Route path="/addnewu" component={AddObitury} />
      <Route path="/feedbacku" component={Feedback} />{" "}
      <Route path="/updateu/:id" component={UpdateObitury} />
      <Route path="/detailview/:id" exact component={DetailViewUser} />
    </Switch>
  );
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialstate);
  const [datax, setDatax] = useState("xx");
  const [loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 54, fontWeight: 100 }} spin />
  );
  useEffect(() => {
    axios.get(`${BASE_URL}/network`).then((response) => {
      setDatax(response.data);
    });

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [datax]);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {datax == "" ? (
        <Net />
      ) : loading ? (
        <div
          style={{
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20%",
            placeItems: "center",
            flexDirection: "column",
          }}
        >
          <Spin size="large" indicator={antIcon} />
          <br />
          <span style={{ color: "gray" }}>Loading...</span>
        </div>
      ) : (
        <Router>
          <Navbar />

          <GlobalStyle />
          <ScrollToTop />
          <Routing />
        </Router>
      )}
    </UserContext.Provider>
  );
}

export default App;
