import React from "react";
import {
  CardSection,
  CardArticle,
  TitlePrimary,
  Description,
  IconStyled,
  Country,
  Phone,
  Hairline,
} from "./card__elements";

const Index = ({ country, title, description }) => {
  return (
    <>
      <CardSection>
        <CardArticle>
          <Country>{country}</Country>
          <Hairline />
          <TitlePrimary>{title}</TitlePrimary>
          <Description>{description}</Description>
        </CardArticle>
      </CardSection>
    </>
  );
};

export default Index;
