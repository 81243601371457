import styled from "styled-components";

export const CardMain = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: auto;
  gap: 10px;
  font-family: sans-serif;
  max-width: 1000px;
`;

export const CardSection = styled.div`
  background: #f6f6f6;
  border-radius: 0.5em;
  margin: 1em;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  color: #333;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: 0.1s ease-in-out;
    color: #fff;
    background: linear-gradient(90deg, #f51922 0%, #f10e53 100%);
  }
`;
export const CardFigure = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardArticle = styled.div`
  padding: 20px;
  text-align: center;
`;

// Typography
export const Country = styled.p`
  font-size: 1.4em;

  margin-bottom: 2px;
  font-weight: 800;
  /* color: #f51922; */
`;

export const Hairline = styled.div`
  padding: 1px;
  opacity: 0.1;
  width: 100%;
  background: linear-gradient(90deg, #f51922 0%, #f10e53 100%);
  margin-bottom: 10px;
  /* color: #f51922; */
`;

export const TitlePrimary = styled.p`
  font-size: 1em;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: 300;
`;

export const Phone = styled.p`
  font-size: 1rem;
  font-weight: 300;
`;

export const Icon = styled.div``;

export const IconStyled = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

// const random_hex_color_code = () => {
//   let n = (Math.random() * 0xfffff * 1000000).toString(16);
//   return "#" + n.slice(0, 6);
// };

// export const Span = styled.span`
//   color: #fff;
//   background: ${random_hex_color_code};
//   padding: 1px 13px;
//   border-radius: 50px;
//   font-size: 0.75em;
//   text-transform: uppercase;
//   word-spacing: 0.0625em;
// `;
