import React, { useState, useEffect } from "react";
import {
  TickerContainer,
  Ticker,
  TickerContent,
  Main,
} from "./Ticker.elements";
import axios from "axios";
import BASE_URL from "../../constant";
import { SiIbeacon } from "react-icons/si";
function Index() {
  const [alldata, setAlldata] = useState([]);

  // Get all data request
  const refreshData = () => {
    axios({
      method: "get",
      url: `${BASE_URL}/api/alltickers`,
    }).then((response) => {
      console.log(response.data.alltickers);
      setAlldata(response.data.alltickers);
    });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);

  return (
    <>
      <Main>
        <TickerContainer>
          <Ticker>
            {alldata.map((item) => (
              <TickerContent>*{item.description}*</TickerContent>
            ))}
          </Ticker>
        </TickerContainer>{" "}
      </Main>
    </>
  );
}

export default Index;
