import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SideBar from "./SideContainer";
import {
  ScreenContainer,
  MainContainer,
} from "../../components/MainContainer/MainContainer.elelents";

import { Container } from "../../globalStyles";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { Table, Tag, Modal, Button, Drawer, Input } from "antd";
import { useForm, Controller } from "react-hook-form";
import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";
import BASE_URL from "../../constant";

function Index(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const history = useHistory();
  const [alldata, setAlldata] = useState([]);
  const [deletevisible, setDeleteVisible] = useState(false);
  const [addcontactvisible, setAddcontactVisible] = useState(false);
  const [keyid, setKeyid] = useState("");
  const [loading, setLoading] = useState(true);

  // Refresh Data
  const refreshData = () => {
    // setLoading(true);
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "get",
      url: `${BASE_URL}/myobitury`,
      headers: axiosHeaders,
    }).then((response) => {
      console.log(response.data);
      setAlldata(response.data);
      setLoading(false);
    });
  };

  // Delete Modal functions
  const showModalDelete = () => {
    setDeleteVisible(true);
  };
  const hideModalDelete = () => {
    setDeleteVisible(false);
  };

  // Filter data to state

  const data = [];

  const deletePost = (obituryId) => {
    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "delete",
      url: `${BASE_URL}/deleteobitury/${obituryId}`,
      headers: axiosHeaders,
    }).then((result) => {
      refreshData();
    });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);

  const onSubmit = (formData) => {
    const finaldata = new FormData();

    // BASIC DETAILS
    finaldata.append("_id", keyid);
    finaldata.append("contactname", formData.contactname);
    finaldata.append("contactmobile", formData.contactmobile);

    const axiosHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    axios({
      method: "put",
      url: `${BASE_URL}/updatenewcontact`,
      data: {
        _id: keyid,
        contactname: formData.contactname,
        contactmobile: formData.contactmobile,
      },
      headers: axiosHeaders,
    })
      .then((apidata) => {
        if (apidata.error) {
          swal({
            timer: 3000,
            title: "Error",
            text: "Not able to upload new contact..",
            icon: "error",
            button: false,
          });
        } else {
          swal({
            timer: 3000,
            title: "Success",
            text: "New contact Updated succesfully !",
            icon: "success",
            button: "Okey",
            button: false,
          });
          setAddcontactVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  for (let i = 0; i < 1; i++) {
    alldata.map((item) => {
      data.push({
        key: item._id,
        name: item.title + `.  ` + item.namedeceased,
        age: item.age,
        dateofbirth: item.dateofbirth,
        dateofdeceased: item.dateofdeceased,
        birthplace: item.birthplace,
        deathplace: item.deathplace,
      });
    });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a href="#">{text}</a>,
    },

    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Date of birth",
      dataIndex: "dateofbirth",
      key: "dateofbirth",
      render: (text) => (
        <Tag color={"magenta"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Date of death",
      dataIndex: "dateofdeceased",
      key: "dateofdeceased",
      render: (text) => (
        <Tag color={"purple"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Birth place",
      dataIndex: "birthplace",
      key: "birthplace",
      render: (text) => (
        <Tag color={"orange"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Death place",
      dataIndex: "deathplace",
      key: "deathplace",
      render: (text) => (
        <Tag color={"blue"} key={text}>
          {text}
        </Tag>
      ),
    },
    {
      title: "Add More Contact",
      key: "addcontact",
      render: (text, record) => (
        <span>
          <a
            onClick={(e) => {
              setAddcontactVisible(true);
              setKeyid(record.key, e);
            }}
            style={{
              color: "white",
              backgroundColor: "purple",
              padding: 4,
              borderRadius: 5,
            }}
          >
            <UserAddOutlined /> Add Contact
          </a>
        </span>
      ),
    },

    {
      title: "Operations",
      key: "action",
      render: (text, record) => (
        <>
          <span>
            <Link
              onClick={(e) => {
                history.push(`/detailview/${record.key}`);
              }}
              style={{
                color: "white",
                marginRight: 10,
                backgroundColor: "darkcyan",
                padding: 4,
                borderRadius: 5,
                paddingLeft: 7,
                paddingRight: 7,
                fontSize: 20,
              }}
            >
              <EyeOutlined />
            </Link>
            <a
              onClick={(e) => {
                // alert(record.key, e);
                history.push(`/updateu/${record.key}`);
                // setKeyid(record.key, e);
              }}
              style={{
                color: "white",
                marginRight: 10,
                backgroundColor: "blue",
                padding: 4,
                paddingLeft: 7,
                paddingRight: 7,
                borderRadius: 5,
                fontSize: 20,
              }}
            >
              <EditOutlined />
            </a>

            <a
              onClick={(e) => {
                showModalDelete(true);
                setKeyid(record.key, e);
              }}
              style={{
                color: "white",
                marginRight: 10,
                backgroundColor: "red",
                padding: 4,
                paddingLeft: 7,
                paddingRight: 7,
                borderRadius: 5,
                fontSize: 20,
              }}
            >
              <DeleteOutlined />
            </a>
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <Container style={{ background: "#fff" }} isZoom>
        {/* FOR DELETE MODAL */}
        <Modal
          title="Delete Confirmation"
          visible={deletevisible}
          onOk={(e) => {
            deletePost(keyid);
            hideModalDelete();
          }}
          onCancel={hideModalDelete}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <ExclamationCircleOutlined
              style={{ fontSize: 50, color: "red", margin: 10 }}
            />
            <p style={{ marginTop: 20, color: "gray", fontSize: 18 }}>
              {" "}
              Please confirm your delete request...
            </p>
          </div>
        </Modal>
        <ScreenContainer style={{ margin: 0, background: "#fff" }} isdisplay>
          <SideBar />
          <MainContainer
            style={{
              display: "flex",
              flex: 1,
              background: "#fff",
              height: "80vh",
              marginTop: 10,
            }}
          >
            <Table
              title={() => (
                <Button
                  style={{
                    width: 200,
                    marginBottom: 10,
                    borderRadius: 50,
                  }}
                  type="primary"
                  onClick={() => {
                    history.push("/addnewu");
                  }}
                >
                  + ADD NEW NOTICE
                </Button>
              )}
              style={{ zoom: "90%" }}
              // bordered

              columns={columns}
              dataSource={data}
              loading={loading}
            ></Table>
          </MainContainer>
        </ScreenContainer>
      </Container>

      <Drawer
        width={450}
        title="Add New contact Details"
        placement="right"
        closable={false}
        onClose={() => {
          setAddcontactVisible(false);
        }}
        visible={addcontactvisible}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginTop: 30 }}>
            <label>Contact Name </label>
            <Input
              style={inputStyles}
              size="large"
              name="contactname"
              placeholder="Contact Name of person rest .."
              {...register("contactname", {
                required: true,
              })}
            />

            {errors.contactname && errors.contactname.type === "required" && (
              <p className="errorMsg">* Contact Name is required.</p>
            )}
          </div>
          <div>
            <label>Contact Mobile </label>
            <Input
              style={inputStyles}
              size="large"
              name="contactmobile"
              placeholder="Contact Mobile of person rest .."
              {...register("contactmobile", {
                required: true,
              })}
            />

            {errors.contactmobile &&
              errors.contactmobile.type === "required" && (
                <p className="errorMsg">* Contact Mobile is required.</p>
              )}
          </div>
          <div
            style={{
              textAlign: "right",
              marginTop: 30,
            }}
          >
            <Button
              style={{ marginRight: 8 }}
              onClick={() => setAddcontactVisible(false)}
            >
              Cancel
            </Button>
            <button
              type="submit"
              style={{
                backgroundColor: "blue",
                color: "white",
                padding: 5,
                border: "none",
                cursor: "pointer",
              }}
            >
              Submit Data
            </button>
          </div>
        </form>
      </Drawer>
    </>
  );
}

const inputStyles = {
  width: 400,
  padding: 10,
};

export default withRouter(Index);
