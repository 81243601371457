import React from "react";
import {
  SivapuranamContainer,
  SivapuranamHeading,
  SivapuranamPdf,
} from "./Sivapuranam.elements";

function Index() {
  return (
    <>
      <SivapuranamContainer>
        <SivapuranamHeading></SivapuranamHeading>
        <SivapuranamPdf src="https://cdn6.aptoide.com/imgs/a/2/c/a2c44d26755197f2c3c8eb9a9de4a3b3_icon.png"></SivapuranamPdf>
        <p
          style={{
            color: "#fff",
            padding: 6,
            marginBottom: 20,
            background: "#101522",
            borderRadius: 50,
            cursor: "pointer",
            marginTop: -50,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          Download Sivapuranam
        </p>
      </SivapuranamContainer>
    </>
  );
}

export default Index;
