import React, { useState, useEffect } from "react";
import { Container } from "../../globalStyles";
import { Collapse, Card, Spin } from "antd";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import BASE_URL from "../../constant";
import { ImDatabase } from "react-icons/im";

const { Panel } = Collapse;

function Index() {
  const [loading, setLoading] = useState(true);
  const [alldata, setAlldata] = useState([]);

  // Get all data request
  const refreshData = () => {
    axios({
      method: "get",
      url: `${BASE_URL}/api/allpoems`,
    }).then((response) => {
      // console.log(response.data.allpoems);
      setAlldata(response.data.allpoems);
      setLoading(false);
    });
  };

  useEffect(() => {
    refreshData();
  }, [alldata]);
  return (
    <>
      {loading ? (
        <Spin
          style={{
            width: "100%",
            marginTop: "20%",
            padding: 30,
            height: "100vh",
          }}
          size="large"
        />
      ) : (
        <>
          <Container
            isZoom
            isbig
            style={{
              height: "100%",
              marginBottom: "30vh",
              padding: 30,
            }}
          >
            {alldata.length === 0 ? (
              <div
                style={{
                  width: "97%",
                  height: "30%",
                  marginTop: 50,
                  borderRadius: 10,
                  marginBottom: 20,
                  border: "2px solid #f1f1f1",
                  padding: "20%",
                  color: "#ddd",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <ImDatabase style={{ fontSize: 50 }} />
                <h1 style={{ fontSize: 30, color: "#eee" }}>No Poems Found</h1>
              </div>
            ) : (
              alldata.map((item) => (
                <Card
                  bordered={true}
                  style={{
                    margin: 10,
                    marginTop: 40,
                    borderRadius: 10,
                    textAlign: "center",
                    // backgroundColor: "tomato",
                    // color: "white",
                  }}
                >
                  <Collapse
                    accordion
                    bordered={false}
                    style={{ backgroundColor: "#fff", fontWeight: 100 }}
                  >
                    <Panel
                      header={item.name}
                      key={item._id}
                      style={pannelstyle}
                    >
                      <div
                        style={{ textAlign: "center" }}
                        className="descriptionView"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </Panel>
                  </Collapse>
                </Card>
              ))
            )}
          </Container>
        </>
      )}
      <Footer />
    </>
  );
}

const pannelstyle = {
  padding: 10,
  Color: "red",
  // display: "flex",
  margin: "0 auto",
  backgroundColor: "#fff",
  textAlign: "center",
};
export default Index;
