export const homeObjOne = {
  primary: true,
  lightBg: false,
  smallpadding: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Exclusive Access",
  headline: "Easy Manuplation and usability",
  description:
    "Send us the photo and the information about the remembrance notice through our platform put your remembrance on live with out any paper works or mailing. ",
  buttonLabel: "Post Yours :)",
  imgStart: "",
  img: require("../../images/009ser.svg"),
  alt: "Credit Card",
  to: "/signin",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Exclusive Access",
  headline: "Easy Manuplation and usability",
  description:
    "Send us the photo and the information about the remembrance notice through our platform put your remembrance on live with out any paper works or mailing. ",
  buttonLabel: "Post Yours :)",
  imgStart: "",
  img: require("../../images/009ser.svg"),
  alt: "Credit Card",
  to: "/signin",
};

export const homeObjThree = {
  // lightBg: true,
  // lightText: false,
  // lightTextDesc: false,
  // topLine: "Easy Setup",
  // headline: "Super fast and simple onboarding process",
  // description:
  //   "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  // buttonLabel: "Start Now",
  // imgStart: "start",
  // img: require("../../images/profile.jpg"),
  // alt: "Vault",
  primary: true,
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Why to choose Us ?",
  headline:
    "PassingWay Help you to create your own condolances with multiple choises !",
  description:
    "Our team is wonderful! You can't believe We all stand with you. for your wise choises.",
  buttonLabel: "View Our Plans :)",
  imgStart: "start",
  img: require("../../images/ff.png"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Data Analytics",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};
