import styled from "styled-components";

import { Link } from "react-router-dom";
export const SideCardWrapper = styled(Link)`
  display: flex;
  /* max-width: 300px; */
  width: 90%;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  border-bottom: "1px solid red";
  cursor: pointer;
  background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%);
  margin-bottom: 5px;
  transition: ease-in-out 0.5s;
  &:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.1s;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SideCardContainerImg = styled.div`
  flex-basis: 20%;
`;
export const SideContainerContent = styled.div`
  flex-basis: 80%;
`;

export const SideImg = styled.img`
  border-radius: 5px;
  height: 80px;
  width: 80px;
  margin-right: 20px;
`;

export const Typography = styled.p`
  color: #000;
  text-align: left;
  display: ${({ isDisplay }) => (isDisplay ? "none" : "default")};
`;

export const Hairline = styled.span`
  background-color: #ddd;
  padding: 2px;
  opacity: 0.7;
`;
